import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';

function HeaderBottom(props) {
    const catMenuRef = useRef();

    const history = useHistory()
    const [searching, setSearching] = useState('')
    const [searchdata, setSearchdata] = useState([])
    const [activeIndex, setActiveIndex] = useState(null)

    const [categories, SetCategories] = useState([])
    const [subcategories, SetSubategories] = useState([])
    const [viewMore, setViewMore] = useState(false)

    const subcatClicked = async (subCatID) => {

        //  history.push(`current-items/${subCatID}`)
        history.push({
            pathname: `/current-items`,
            search: `?subcategoryId=${subCatID}`,
            //state: { detail: subCatID }
        })

    }


    const addActiveClass = (location) => {
        if (+window.innerWidth > 1010) {
            console.log("More", window.innerWidth);
            if (location.pathname == '/') {
                catMenuRef.current.classList.add('active')
            } else {
                catMenuRef.current.classList.remove('active')
            }
        } else {
            console.log("Less", window.innerWidth);
            catMenuRef.current.classList.remove('active')

        }
    }


    useEffect(() => {
        console.log('history location is: ', history.location)
        if (history.location.pathname == '/') {
            addActiveClass(history.location);
        }
        history.listen((location, action) => {
            addActiveClass(location);
            setViewMore(false)
        });

    }, [])


    // Search Api
    const searchapi = async () => {
        if (searching) {
            var response = await axios.get(`searching/${searching}`)
            console.log("mysearching", response.data.data)
            setSearchdata(response.data.data)

        }
    }
    useEffect(() => {
        searchapi()
    }, [searching])

    // Category Subcategory Api

    const categorysubcategoryapi = async (index, type) => {

        console.log(`%c Index is: ${index} and Type is: ${type}`, 'color: red;')

        // PURPOSE OF BELOW IF/ELSE :-
        // When we run this site on small devices we need 'onClick' instead of 'onMouseEnter',
        // because 'onMouseEnter' will not give us open/close functionality on clicking in small devices
        // then, when we put 'onClick', 'onMouseEnter' was working with it too, which was resulting in 
        // calling of this function twice
        if(type == 'onClick' && +window.innerWidth < 1010) {
            if( activeIndex == index) {
                setActiveIndex(null)
            } else {
                setActiveIndex(index)
            }
        } else if (type == 'onMouseEnter' && +window.innerWidth > 1010) {
            if( activeIndex == index) {
                setActiveIndex(null)
            } else {
                setActiveIndex(index)
            }
        }

        // var response = await axios.get(`categorysubcategory`)
        console.log("categories", categories[index])
        // SetCategories(response.data.data)
        console.log("subcaategory", categories[index].subcategory)
        if (index != null) {
            SetSubategories(categories[index]?.subcategory)
        }
        else {
            // SetSubategories(categories[0]?.subcategory)
            SetSubategories([])
        }

    }


    const initialcategorysubcategoryapi = async () => {

        var response = await axios.get(`categorysubcategory`)

        SetCategories(response.data.data)

        SetSubategories(response.data.data[0]?.subcategory)


    }


    useEffect(() => {
        // searchapi()
        // categorysubcategoryapi()
        initialcategorysubcategoryapi()
    }, [searching])

    useEffect(() => {
        console.log('Active Index Currently is: ', activeIndex)

    },[activeIndex])

    return (
        <div class="header_inner">
            <div class="header_bottom">
                <div class="container">
                    <div class="header_bottom_box d-new-a">
                        <div class="cat_list_outer">
                            <div class="cat_list_head">
                                <img src="/images/icon-toggle.png" alt="" />
                                <span>Categories</span>
                                <div class="icon-caret">
                                    <img src="/images/icon-caret.png" alt="" />
                                </div>
                            </div>
                            <div class="cat_menu" ref={catMenuRef}>
                                <div class="cat_menu_item">
                                    <ul>

                                        {
                                            categories.filter((_, index) => index < (viewMore ? categories.length : 8)).map((items, index) => {
                                                return (
                                                    <li class={activeIndex == index ? "hasMenu active" : "hasMenu"} key={`categoryKey${index}`}>

                                                        {
                                                            window.innerWidth > 1010 ?
                                                                <a
                                                                    onMouseEnter={() => categorysubcategoryapi(index, 'onMouseEnter')}
                                                                >


                                                                    <div class="cat_menu_icon"  >
                                                                        <img src={items.image} alt="" />
                                                                    </div>
                                                                    <span>{items.name}</span>
                                                                </a>
                                                            :
                                                                <a
                                                                    onClick={() => categorysubcategoryapi(index, 'onClick')}
                                                                >


                                                                    <div class="cat_menu_icon"  >
                                                                        <img src={items.image} alt="" />
                                                                    </div>
                                                                    <span>{items.name}</span>
                                                                </a>

                                                        }

                                                        {
                                                            activeIndex == index &&
                                                            <div class="submenu" style={{ display: 'block'}}>
                                                                {
                                                                    subcategories.length ?
                                                                    <ul>
                                                                        {subcategories.map((items, index) => {
                                                                            return (
                                                                            <li key={`subcategoryKey${index}`}>
                                                                                <a onClick={() => subcatClicked(items.id)}>
                                                                                    <div class="cat_menu_icon">
                                                                                        <img src={items.image} alt="" />
                                                                                    </div>
                                                                                    <span>{items.name}</span>
                                                                                </a>
                                                                            </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                    :
                                                                    <></>

                                                                }
                                                            </div>

                                                        }
                                                    </li>)
                                            })

                                        }

                                        {/* <div class="submenu">
                                                <ul>
                                                    <li>
                                                        {subcategories.map((items, index) => {
                                                            return (<a onClick={() => subcatClicked(items.id)}>
                                                                <div class="cat_menu_icon">
                                                                    <img src={items.image} alt="" />
                                                                </div>
                                                                <span>{items.name}</span>
                                                            </a>)
                                                        })}
                                                    </li>
                                                </ul>
                                            </div> */}

                                    </ul>
                                </div>
                                {
                                    categories.length > 0 &&
                                    <div onClick={() => setViewMore(prevState => !prevState)} class={"view_more_cat" + (viewMore ? ' active' : '')}>
                                        <h5 >{!viewMore ? 'More Categories' : 'Less Categories'}</h5>
                                        <img src="/images/icon-caret-orange.png" alt="" />
                                    </div>

                                }
                            </div>
                        </div>
                        <div class="search_outer flex-grow-1">
						<div class="form-group has-search">
    <span class="form-control-feedback"><img class="img1" src='https://justacredit.com/images/search64.png'/></span>
    <input type="text" class="form-control" placeholder="Search here for items, just start typing..." onChange={(event) => setSearching(event.target.value)} name="" />
  </div>
                            
                            
                            <div class="jsearch_drop">
                                <ul>
                                    <li>
                                        {searchdata.map((items, index) => {
                                            return (
                                                <a href="" onClick={() => history.push({ pathname: `/item-details/${items.id}` })}>
                                                    <div class="jsearch_img">
                                                        <img src={items.image} alt="" />
                                                    </div>
                                                    <span>{items.name}</span>
                                                </a>
                                            )
                                        })
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pageOverlay"></div>
        </div>

    )
}

export default HeaderBottom
