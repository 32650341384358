import axios from "axios";
import React from "react";
import AdSense from 'react-adsense';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Items from "../Components/CurrentItems/Items";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CurrentItems = (props) => {

  const query = useQuery();
  const subcategoryId = query.get('subcategoryId');
  const categoryId = query.get('categoryId');

  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [pages, setPages] = useState(1);
  const [categoryName, setCategoryName] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');
  const [fetched, setFetched] = useState(false)
  const user = useSelector((state) => state.user.user);

  useEffect(() => {

    getProducts(1);
  }, [subcategoryId]);

  const getProducts = (index) => {

    var url = ''
    if (subcategoryId) {
      url = `get_product_category/${subcategoryId}?page=${index}`

    } else if( categoryId ) {
      url = `getproduct_papular_category_wise/${categoryId}?page=${index}`
    } else {
      url = `get_product?page=${index}`
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data.status == true) {
          console.log("Products are: ", res.data.data);
          setProducts(res.data.data.data);
          setPagination(res.data.data);
          setPages(Math.ceil(res.data.data.total / res.data.data.per_page))

          if(res.data.category) {
            setCategoryName(res.data.category)
            setSubCategoryName(res.data.subcategory)
          } else {
            setCategoryName('')
            setSubCategoryName('')
          }

          console.log("New Product Array: ", products);
        }
      })
      .catch((err) => console.log(err))
      .finally(_ => setFetched(true))
  };
  const pushToDetail = (productId) => {
    props.history.push({ pathname: `/item-details/${productId}` });
    window.scrollTo(0, 0); 
  };

  return (
    <section className="banner_outer_box ">
      <div className="container ">
        <div className="row mb-4">
          <div className="login_sub_title col-3 py-3">
            {
              fetched &&
                <h2>{ categoryName ? `${categoryName}${subCategoryName ? ' / ' + subCategoryName : ''}` : 'Current Items' }</h2>
            }
          </div>
          <div class="col-9">
            <AdSense.Google
                client='ca-pub-1905261535699460'
                slot='9492690526'
                style={{ width: 970, height: 90, float: 'left' }}
                format=''
            />
          </div>
        </div>
        {products.length == 0 ? (
          <p className="lead text-muted" style={{ marginBottom: "100px" }}>
            {
              fetched &&
              'No items found, Please try again later'
            }
          </p>
        ) : null}
        <Items products={products} pushToDetail={pushToDetail} user={user} />



        {/* NEW CUSTOM PAGINATION */}

        {pages > 1 && (
          <div className="paginationCustom">
            <div
              className="pageElement"
              onClick={() => getProducts(pagination.current_page - 1)}
            >
              <p className="indexElement">&lt;</p>
            </div>

            {[...Array(pages).keys()]
              .map((index) => index + 1)
              .map((index) => (
                <div
                  key={index}
                  className={
                    "pageElement" +
                    (pagination.current_page == index ? " pageActive" : "")
                  }
                  onClick={() => getProducts(index)}
                >
                  <p className="indexElement">{index}</p>
                </div>
              ))}

            <div
              className="pageElement"
              onClick={() => getProducts(pagination.current_page + 1)}
            >
              <p className="indexElement">&gt;</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CurrentItems;
