import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import swal from "sweetalert";
import axios from "axios";
import {Link} from 'react-router-dom';
import GoogleAd from "../Ads/GoogleAd";
import AdSense from "react-adsense";


const useStyles = makeStyles((theme) => ({
    errmsg: {
        margin: '20px',
        color: "red",
        fontSize: '14px',
        lineHeight: '0px',
    },
}));

export default function SignUp(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [emailExist, setEmailExist] = useState(false)
    const [emailMessage, setMessage] = useState('')
    const [user, setUser] = useState({
        fname: "",
        lname: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        email: "",
        username: "",
        password: "",
        confirm_password: "",
    });
    const [error, setError] = useState({
        allValid: false,
        fname: false,
        lname: false,
        street_address: false,
        city: false,
        state: false,
        zip_code: false,
        email: false,
        username: false,
        password: false,
        confirm_password: false,

    });

    const [terms, setTerms] = useState({
        years: false,
        read: false,
    });

    const [states, setStates] = useState([]);

    const fetchstates = async () => {
        try {
            var response = await axios.get("state");
            setStates(response.data.data);
        } catch (error) {
            console.log("state API error: ", error);
        }
    };
    const renewUser = () => {
        setUser({
            fname: "",
            lname: "",
            street_address: "",
            city: "",
            state: "",
            zip_code: "",
            email: "",
            username: "",
            password: "",
            confirm_password: "",
        });

    };
    useEffect(() => {
        fetchstates();
    }, []);

    const changeTermsState = (e) => {
        setTerms((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.checked == true ? true : false,
        }));
        console.log("TERMS", terms);

    };

    const changeUserState = (e) => {
        //console.log("e get : ", e.target, e.target.name, e.target.value);

        setUser((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };


    const renewError = () => {
        setError({
            allValid: '',
            fname: '',
            lname: '',
            street_address: '',
            city: '',
            state: '',
            zip_code: '',
            email: '',
            username: '',
            password: '',
            confirm_password: '',
            samePassword: ''
        });
        // setEmailExist(false)
    };

    const renewTerms = () => {
        setTerms({
            years: false,
            read: false,
        });
    };


    const handleSubmit = async () => {

        renewError();
        if (user.fname == "") {
            setError({fname: "Please enter your first name."})
        } else if (user.lname == "") {
            setError({lname: "Please enter your last name."})
        } else if (user.street_address == "") {
            setError({street_address: "Please enter your street address."})
        } else if (user.city === "") {
            setError({city: "Please enter city."})
        } else if (user.state == "") {
            setError({state: "Please enter state."})
        } else if (user.zip_code == "") {
            setError({zip_code: "Please enter your zip code."})
        } else if (user.email == "") {
            setError({email: "Please enter your email."})
        } else if (user.username == "") {
            setError({username: "Please enter your username."})			
        } else if (user.password == "") {
            setError({password: "Please enter your  password."})
        } else if (user.password.length < 8) {
            setError({samePassword: "Length of password needs to be greater than eight characters."})
        } else if (user.confirm_password == "") { 
            setError({confirm_password: "Please confirm password"})
        } else if (user.password !== 0 && user.confirm_password !== 0) {
            if (user.password !== user.confirm_password) {
                setError({samePassword: "Your password does not match."})
            } else if (terms.read && terms.years) {
				
				 if (user.username.length  !== 0) {
			         let username2 = user.username;
                if (/^[a-zA-Z0-9_]+$/.test(username2)) {
                     try {
                    if (!user.email == "") {

                        var response = await axios.post(`register`, user);
                        if (response.data.status == true) {
                            swal({
                                title: "You have successfully joined JustaCredit",
                                text: "A verification link has been sent to your email",
                                icon: "success",
                                button: "OK",
                            });

                            renewUser();
                            renewError();
                            renewTerms();
                            // setEmailExist(false)
                            props.history.push({pathname: "/login"});
                            // window.location.href = '/'
                        } else if (response.data.status == false) {
                            setMessage("")
                            if (response.data?.message?.email.length > 0) {
                                setMessage("")
                                setEmailExist(true)

                                setMessage(response.data?.message?.email[0])
                                swal({
                                    title: response.data?.message?.email[0],

                                    icon: "error",
                                    button: "OK",
                                });
                                console.log("exictsssss");

                            }
                        }
                    }
                } catch (error) {
                    console.log("register error: ", error);

                    swal({
                        title: "Something went wrong",
                        text: "Record Not Submited",
                        icon: "",
                        button: "OK",
                    });
                }
             } else {
                     setError({username: "Username is invalid"})
                    }	
		}

               

            }


        }

    };

    return (
        <div>
            <section className="account_box_outer mt-120 ">
                <div className="container">
                    <div class="row">
                        <div className="col-3 mx-auto">
                            <AdSense.Google
                                client='ca-pub-1905261535699460'
                                slot='8534035914'
                                style={{ width: 300, height: 600, float: 'left' }}
                                format=''
                            />
                        </div>
                        <div class="col-9 mb-5">
                            <div className="row">
                                <div className="login_sub_title col-md-9">
                                    <h2>Sign up </h2>
                                </div>
                            </div>
                            <div className="form_box pr-xl-5 ">
                                <div className="row mlr-24 ">
                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="First Name"
                                                name="fname"
                                            />

                                        </div>
                                        {error.fname ? (
                                            <p className={classes.errmsg}>
                                                {error.fname}
                                            </p>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">

                                            <input
                                                type="text"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="Last Name"
                                                name="lname"
                                            />
                                        </div>
                                        {error.lname ? (
                                            <p className={classes.errmsg}>
                                                {error.lname}
                                            </p>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">

                                            <input
                                                type="text"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="Address"
                                                name="street_address"
                                            />
                                        </div>
                                        {error.street_address ? (
                                            <p className={classes.errmsg}>
                                                {error.street_address}
                                            </p>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">

                                            <input
                                                type="text"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="City"
                                                name="city"
                                            />
                                        </div>
                                        {error.city ? (
                                            <p className={classes.errmsg}>
                                                {error.city}
                                            </p>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">

                                            <select
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                name="state"
                                            >
                                                <option>Select State...</option>
                                                {states.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {error.state ? (
                                            <p className={classes.errmsg}>
                                                {error.state}
                                            </p>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">

                                            <input
                                                type="text"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="Zip Code"
                                                name="zip_code"
                                            />
                                        </div>
                                        {error.zip_code ? (
                                            <p className={classes.errmsg}>
                                                {error.zip_code}
                                            </p>
                                        ) : null}
                                    </div>
									</div>
									<br />
                                <div className="row mlr-24 ">
                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">


                                            <input
                                                type="text"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="Email Address"
                                                name="email"
                                            />
                                        </div>
                                        {error.email ? (
                                            <p className={classes.errmsg}>
                                                {error.email}
                                            </p>
                                        ) : null}


                                    </div>
								</div>
								<br />
                                <div className="row mlr-24 ">
                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">

                                            <input
                                                type="text"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="Create Username"
												autoComplete="text"
                                                name="username"
                                            />

                                        </div>
                                        {error.username ? (
                                            <p className={classes.errmsg}>
                                                {error.username}
                                            </p>
                                        ) : null}
                                    </div>
								</div>
                                <div className="row mlr-24 ">
                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">


                                            <input
                                                type="password"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="Create Password"
												autoComplete="new-password"
                                                name="password"
                                            />
                                        </div>
                                        {error.password ? (
                                            <p className={classes.errmsg}>
                                                {error.password}
                                            </p>
                                        ) : null}
                                        {error.samePassword ? (
                                            <p className={classes.errmsg}>
                                                {error.samePassword}
                                            </p>
                                        ) : null}
                                    </div>

                                    <div className="col-md-6 plr-24">
                                        <div className="form-group">

                                            <input
                                                type="password"
                                                className="form-control form_input"
                                                onChange={(e) => changeUserState(e)}
                                                placeholder="Confirm password"
                                                name="confirm_password"
                                            />
                                        </div>
                                        {error.confirm_password ? (
                                            <p className={classes.errmsg}>
                                                {error.confirm_password}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="col-md-12 plr-24 mb-4 mt-3">
                                        <div className="form-check form-check-inline mb-2">
                                            <input
                                                className="form-check-input chk1 "
                                                onChange={(e) => changeTermsState(e)}
                                                type="checkbox"
                                                name="years"
                                            />
                                            <label className="form-check-label ml-0">
                                                I am 18 years of age or older or have my parent’s permission
                                                to
                                                use JustaCredit.

                                            </label>
                                        </div>
                                        {" "}
                                        <br/>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input chk2 "
                                                onChange={(e) => changeTermsState(e)}
                                                type="checkbox"
                                                name="read"
                                            />
                                            <label className="form-check-label ml-0">
                                                I have read and understand the
                                                <Link to='/terms'><span
                                                    className="text_orange font-weight-bold">
                            {" "}Terms and Conditions
                          </span></Link>
                                            </label>
                                        </div>
                                    </div>
                                    <h3></h3>
                                    <div className="col-md-6 plr-24 mb-4">
                                        <button
                                            type="submit"
                                            className="btn btn-blue w-50 btn-b"
                                            onClick={handleSubmit}
                                            disabled={false}
                                        >
                                            Join JustaCredit
                                        </button>
                                    </div>
                                    <div className="col-md-12 plr-24">
                                        <p>
                                            Already have an account?
                                            <Link to="/Login">
                                              <span className=" text_orange font-weight-bold">{" "} Log In</span>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
