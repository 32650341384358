import TopItems from "../Components/Home/TopItems"
import LatestItems from "../Components/Home/LatestItems"
import MostViewed from "../Components/Home/MostViewed"
import MostViewedTop from "../Components/Home/MostViewedTop"
import PopularCategories from "../Components/Home/PopularCategories"

import { Backdrop } from "@material-ui/core"
import AdSense from "react-adsense";
import React from "react";
export default function Index(props) {
    return (<div>
        <section className="banner_outer_box">
            <div className="container">
                <div className="banner_box " >
                    <div className="row mr-xl-2">
                        <div className="col-xl-9 banner_slider1">
                            <div className="item">
                                <div className="banner_img" >
                                    <img src="/images/banner_img.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <TopItems />
                        </div>
                    </div>
                </div>
            </div>
        </section>
		<MostViewedTop />
        <LatestItems />
        <section className="work_outer">
            <div className="container">
                <div className="title text-center">
                    <h2>How It Works</h2>

</div>
                <div className="work_list">
                    <div className="row">
                        <div className="col-md-3 col-lg-3">
                            <div className="work_item">
                                <div className="work_img">
                                    <img src="/images/icon-work_01.png" alt="" />
                                    <div className="work_number">1</div>
                                </div>
                                <p>Add Your Item</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="work_item">
                                <div className="work_img">
                                    <img src="/images/icon-work_02.png" alt="" />
                                    <div className="work_number">2</div>
                                </div>
                                <p>Members Can Claim Your Item For Free</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="work_item">
                                <div className="work_img">
                                    <img src="/images/icon-work_03.png" alt="" />
                                    <div className="work_number">3</div>
                                </div>
                                <p>Ship Item To Member
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="work_item">
                                <div className="work_img">
                                    <img src="/images/icon-work_04.png" alt="" />
                                    <div className="work_number">4</div>
                                </div>
                                <p>You Earn One Credit To Claim Something You Want</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <MostViewed />
        <PopularCategories/>
    </div >
    )
}