import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {useLocation} from "react-router-dom";
import swal from "sweetalert";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Dashboard() {
    
    const query = useQuery();
    const flag = query.get("flag");
    const userName = useSelector((state) => state.user.user.username);
    const [credit, setCredit] = useState("");
    const [rating, setRating] = useState("");
    const [dashboarddata, setDashboardData] = useState([]);

    useEffect(() => {
        showAlert();
        fetchDashboard();
    }, []);

    useEffect(() => {
        // setUsername(userName)
        getCredit();
    }, []);

    const fetchDashboard = async () => {
        var res = await axios.get("pages");
        console.log("Dashboard", res);
        setDashboardData(res.data.data);
    };
    const showAlert = () => {
        if (flag == "True") {
            swal({
                title: "Payment Was Successfull.",
                icon: "success",
                button: "OK",
            });
        } else if (flag == "False") {
            swal({
                title: "Payment was canceled",
                icon: "error",
                button: "OK",
            });
        }
    };
    const getCredit = async () => {
        try {
            const res = await axios.get("token");
            console.log("mydashboard", res.data);
            if (res.data.status == true) {
                setCredit(res.data.response.credits);
                setRating(res.data.response.rating);
            }
            console.log("TOKEN", res);
        } catch (error) {
            console.log("error");
        }
    };

    return (
        <div className="account_content">
            <div className="acco_box pt-3">
                <div className="dashboard_title ">
                    <h2>
                        {dashboarddata.length !== 0 ? (
                            <h2>{dashboarddata[2].title}</h2>
                        ) : (
                            ""
                        )}
                    </h2>
                    <h2 className="ml-auto">Credit Balance: {credit} </h2>
                </div>

                <div className="mt-4">
                    <p className="text-secondary mt-3">
                        Hello, <span className="text_blue">{userName}</span> (
                        <span style={{color: "orange", fontWeight: "bold"}}>
              {rating}
            </span>
                        )
                    </p>
                    <p className="text-secondary mt-3">
                        {dashboarddata.length !== 0 ? (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: dashboarddata[2].description,
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
