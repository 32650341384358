import React, { useState, useEffect } from 'react'
import axios from 'axios'
import swal from "sweetalert";
import {
    BrowserRouter as Router, Route, Link, Switch, useParams,
    useRouteMatch
} from "react-router-dom"
function AccountDetails() {
    let { path, url } = useRouteMatch();
    const accountDetails = async () => {
        try {
            const fetch = await axios.get('user_profile')
            console.log("profile data", fetch.data.data);
            if (fetch.status) {
                console.log("address", fetch.data.data.address);
                console.log("street", value.street_address);
                setValue({
                    fname: fetch.data.data.fname,
                    lname: fetch.data.data.lname,
                    street_address: fetch.data.data.address,
                    city: fetch.data.data.city,
                    state: fetch.data.data.state,
                    zip_code: fetch.data.data.zip_code,
                    username: fetch.data.data.username,
                    email: fetch.data.data.email,

                })
            }
        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => {
        accountDetails()
        fetchstates();
        console.log("url", {
            path, url
        });
    }, []);
    const [value, setValue] = useState({
        fname: "",
        lname: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        username: "",
        email: "",

    })
    const [error, setError] = useState({
        errorFirstName: "",
        errorLastName: "",
        errorUserName: "",
        errorEmail: "",
        errorNewPassword: "",
        errorConfirmPassword: ""
    })
    //for form  and no password
    const submitHandler = async () => {

        try {
            const res = await axios.post('update_profile', value)
            console.log("post", res);
            setError({
                ...error,
                errorFirstName: res.data.message.fname,
                errorLastName: res.data.message.lname,
                errorEmail: res.data.message.email,
                errorUserName: res.data.message?.username,
                errorConfirmPassword: "",
                errorPasswordNotSame: "",
                errorPasswordField: ""



            })
            if (res.data.status == true) {

                swal({
                    title: "Account Details Updated Successfully",

                    icon: "success",
                    button: "OK",
                });
            }
        } catch (error) {
            console.log(error, error.response?.data);
            // setError({
            //     ...error,
            //     errorConfirmPassword: error.response?.data
            // })
        }


    }
    const changehandler = (e) => {
        e.preventDefault()

        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
        console.log(value);
    }
    //fetch states

    const [states, setStates] = useState([]);

    const fetchstates = async () => {
        try {
            var response = await axios.get("state");
            setStates(response.data.data);
        } catch (error) {
            console.log("state API error: ", error);
        }
    };
    // for password
    const [password, setPassword] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    })
    const onPasswordCHange = (e) => {
        setError({
            errorConfirmPassword: "",
            errorPasswordNotSame: "",
            errorPasswordField: "",

        })
        setPassword({
            ...password,
            [e.target.name]: e.target.value
        }
        )
        console.log("password", password)
    }
    const updatePassword = async () => {
        if (password.old_password === "" || password.new_password === "" || password.confirm_password === "") {
            console.log(" empty");

            setError({
                errorPasswordField: "Please input all fields",
                errorConfirmPassword: ''
            })
        }
        else {
            if (password.new_password !== password.confirm_password) {
                setError({
                    errorPasswordNotSame: "New and Confirm password not same"
                })
                console.log("not smae");
            }
            else {

                try {
                    const res = await axios.post('change_password', password)
                    console.log("password post", res);
                    swal({
                        title: "Password changed successfully",

                        icon: "success",
                        button: "OK",
                    });
                } catch (error) {
                    // swal({
                    //     title: "Something went wrong",

                    //     icon: "error",
                    //     button: "OK",
                    // });
                    setError({
                        errorConfirmPassword: error.response.data[0]
                    })
                    console.log("error", error.response.data[0]);
                }
            }
        }
    }
    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>Account Details</h2>
                </div>
                <div className="form_box">
                    <div className="row mlr-24">
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <input type="text" className="form-control form_input" placeholder="First Name" name="fname" onChange={changehandler} value={value.fname} />
                                {error.errorFirstName ? <p style={{ color: "red" }}>First Name field is required</p> : null}
                            </div>


                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <input type="text" className="form-control form_input" placeholder="Last Name" name="lname" onChange={changehandler} value={value.lname} />
                                {error.errorLastName ? <p style={{ color: "red" }}>Last Name field is required</p> : null}
                            </div>
                        </div>

                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <input type="text" className="form-control form_input" placeholder="Address" name="street_address" onChange={changehandler} value={value.street_address} />
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <input type="text" className="form-control form_input" placeholder="City" name="city" onChange={changehandler} value={value.city} />
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <select className="form-control form_input" name="state" onChange={changehandler} value={value.state}>
                                    <option>Select State...</option>
                                    {states.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form_group mb-50">
                                <input type="text" className="form-control form_input" placeholder="Zip Code" name="zip_code" onChange={changehandler} value={value.zip_code} />
                            </div>
                        </div>
						<div className="col-md-12 plr-24">
                            <div className="form_group">
                                <label className="label">Email Address</label>
                                <input type="email" className="form-control form_input" placeholder="yourmail@domain.com" name="email" onChange={changehandler} value={value.email} />
                                <p style={{ color: "red" }}>{error.errorEmail}</p>
                            </div>
                        </div>
                        <div className="col-md-12 plr-24">
                            <div className="form_group">
                                <label className="label">Username</label>
                                <input type="text" className="form-control form_input" placeholder="UserName" name="username" value={value.username} readOnly />
                                <p style={{ color: "red" }}>{error.errorUserName}</p>
                            </div>
                        </div>
                        
                        <div className="col-md-6 plr-24" >
                            <button type="submit" className=" btn-blue btn-blue-big" onClick={submitHandler} style={{ marginLeft: '0' }}>Save Changes</button>
                        </div>
                        <div className="col-md-12 plr-24">
                            <div className="sub_title_small">
                                <h3>Change Password</h3>
                            </div>
                        </div>
                        <div className="col-md-12 plr-24">
                            <div className="form_group">
                                <label className="label">Current Password</label>
                                <input type="password" className="form-control form_input" name="old_password" onChange={onPasswordCHange} />
                                <p style={{ color: "red", marginTop: '15px' }}>{error.errorConfirmPassword}</p>
                                <p style={{ color: "red", marginTop: '15px' }}>{error.errorPasswordNotSame}</p>
                                <p style={{ color: "red", marginTop: '15px' }}>{error.errorPasswordField}</p>
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <label className="label">New Password</label>
                                <input type="password" className="form-control form_input" placeholder="Create a password" name="new_password" onChange={onPasswordCHange} />
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <label className="label">Confirm Password</label>
                                <input type="password" className="form-control form_input" placeholder="Create a password" name="confirm_password" onChange={onPasswordCHange} />


                            </div>
                        </div>
                        <div className="col-md-6 plr-24" >
                            <button type="submit" className=" btn-blue btn-blue-big" onClick={updatePassword} style={{ marginLeft: '0' }}>Change Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>





    )
}

export default AccountDetails
