import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import {useHistory} from "react-router-dom";
import swal from "sweetalert";

const Messages = (props) => {

    const history = useHistory();
    const user = useSelector((state) => state.user.user);
    const [messages, setMessages] = useState({
        messages: []
    });

    useEffect(async () => {
        await mounted();
    }, []);

    const mounted = async () => {
        await getMessages()
    };

    const getMessages = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`messages`);
                setMessages({
                    messages: response.data
                })
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    };

    const deleteMessage = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.delete(`message/${id}`);
                swal({
                    title: "Message deleted successfully",
                    icon: "success",
                    button: " OK",
                }).then(() => {
                    getMessages();
                })
            } catch (error) {
                reject(error);
            }
        });
    };

    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>My Messages</h2>
                </div>
                <div className="form_box">
                    <table width="100%">
                        <tbody>
                        {messages.messages.length !== 0 ? (
                            messages.messages.map((item) => (
                                <tr className="message">
                                    <td>
                                        {item.from_user_id == user.id ? (
                                            <p>Sent</p>
                                        ) : (
                                            <b>{item.read ? (<p>Read</p>) : (
                                                <p class="text_red">
                                                    <img src="/images/new-message.svg" class="table-icon" />
                                                    New Message!
                                                </p>
                                            )}</b>
                                        )}
                                    </td>
                                    <td>{item.created_at}</td>
                                    {item.from_user_id == user.id ? (
                                        <td><b>To: </b> {item.to.name}</td>
                                    ) : (
                                        <td><b>From: </b>{item.from.name}</td>
                                    )}
                                    <td class="table-options">
                                        <button
                                            className="btn btn_small_blue w-100 btn-green-hover"
                                            onClick={() =>
                                                history.push(
                                                    `/myaccount/message/${item.id}`
                                                )
                                            }>Open
                                        </button>
                                    </td>
                                    <td class="table-options">
                                        <button
                                            className="btn btn_small_blue w-100 btn-green-hover"
                                            onClick={() => {
                                                deleteMessage(item.id)
                                            }}>Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <p>No Messages</p>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Messages;
