import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
function SellCredit() {
  const [sellData, setSellData] = useState([]);

  const fetchSellData = async () => {
    var res = await axios.get("pages");
    console.log("selldata", res);
    setSellData(res.data.data);
  };

  useEffect(() => {
    fetchSellData();
  }, []);
  const [values, setValues] = useState({
    creditValue: "",
    email: "",
  });
  const [error, setError] = useState({
    message: "",
  });
  const [method, setMethod] = useState("");
  const sellCredit = async () => {
    setError({ message: "" });
    try {
      if (values.creditValue == "" || values.email == "") {
        console.log("clickssss");
        setError({ message: "Please fill all inputs" });
      } else {
        const data = {
          credit: parseInt(values.creditValue),
          email: values.email,
          description: "credits",
          method_type: method,
        };

        const response = await axios.post("sell_credit", data);
        if (response.data.status === true) {
          if (values.creditValue == "1") {
            swal({
              title: `${values.creditValue} Credit Sold Successfully`,
              icon: "success",
              button: "OK",
            });
          } else {
            swal({
              title: `${values.creditValue} Credits Sold Successfully`,
              icon: "success",
              button: "OK",
            });
          }
          setValues({
            creditValue: "",
            email: "",
          });
        } else if (response.data.status == false) {
          setError({
            message: "",
          });
          setError({
            message: response.data?.message.email[0],
          });
        }
        console.log(response);
      }
    } catch (error) {
      swal({
        title: "Something went wrong",
        icon: "error",
        button: "OK",
      });
      setValues({
        creditValue: "",
        email: "",
      });
    }
  };
  const onchangeValue = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    console.log("values", values);
  };
  const paymentMethod = (e) => {
    console.log("BUY", e.target.value);
    setMethod(e.target.value);
  };
  return (
    <div className="account_content">
      <div className="acco_box">
        <div className="sub_title pb-4">
          {/* <h2>Sell Credit(s)</h2> */}
          {sellData.length !== 0 ? <h2>{sellData[5].title}</h2> : ""}

          <p className="text-muted mt-3">
            {/* Some members may start to accumulate a lot of credits. Members may
            have the option to sell back their credits to us. We pay $1 per
            credit. Please make sure that we are currently buying back credits.
            If we are currently buying back credits, please enter the amount of
            credits that you would like to sell back to us. Please indicate how
            you would like to receive payment. We offer a few different payment
            methods. We can send payment through PayPal, please supply your
            PayPal e-mail address. We can mail a check or money order to your
            home, please confirm your mailing address. At this time, we are NOT
            buying back any credits. Please check back periodically because this
            changes daily, weekly and monthly. */}
            {sellData.length !== 0 ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: sellData[5].description,
                }}
              />
            ) : (
              ""
            )}
          </p>
        </div>
        <div className="form_box pr-xl-5 ">
          <div className="row mlr-24 ">
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 ">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter the number"
                      maxLength={4}
                      name="creditValue"
                      value={values.creditValue}
                      onChange={(e) => onchangeValue(e)}
                    />
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="form-group">
                    <select
                      name=""
                      className="form-control "
                      id=""
                      onChange={(e) => paymentMethod(e)}
                    >
                      <option value="" disabled selected>
                        Select Payment Method
                      </option>
                      <option value="paypal">Paypal Payment</option>
                      <option value="us mail">U.S. Mail</option>
                    </select>
                  </div>
                </div>

                {/* <div className="col-md-4 plr-24">
                                        
                                    </div> */}
                {method == "paypal" ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Enter Paypal E-mail"
                        name="email"
                        value={values.email}
                        onChange={(e) => onchangeValue(e)}
                      />
                    </div>
                    <div style={{ color: "red" }}>{error.message}</div>
                  </div>
                ) : null}

                {method === "us mail" ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Enter Mailing Address"
                        name="email"
                        value={values.email}
                        onChange={(e) => onchangeValue(e)}
                      />
                    </div>
                    <div style={{ color: "red" }}>{error.message}</div>
                  </div>
                ) : null}
                {method == "" ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Please Select Payment Option"
                        name="email"
                        value={values.email}
                        onChange={(e) => onchangeValue(e)}
                        disabled
                      />
                    </div>
                    <div style={{ color: "red" }}>{error.message}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 plr-24 mb-4">
              {/* <button type="submit" className="btn btn-blue w-50 btn-b rounded-0" onClick={sellCredit}>Sell Credit</button> */}
              <button
                type="submit"
                className=" btn-blue w-50 rounded-0 "
                onClick={sellCredit}
              >
                Sell Credit(s)
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellCredit;
