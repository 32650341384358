import axios from "axios";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import swal from "sweetalert";

const ShippingDetails = (props) => {

    const [shippings, setShippings] = useState([]);
    const {itemId} = useParams();
    const [values, setValues] = useState({
        carrier: '',
        trackingnumber: '',
    })
    const update = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }
    const getShippings = async () => {
        axios.get(`shippings`).then((res) => {


            setShippings(res.data.data);
            setValues((values) => ({
                ...values,
                carrier: res.data.data[0].id,
            }));

            getShippingInfo();

        }).catch((err) => {
            console.log(err);
        })
    }
    const getShippingInfo = async () => {
        axios.get(`myitem_shipping_info/${itemId}`).then((res) => {
            if (res.data.shipping_info) {
                setValues((values) => ({
                    ...values,
                    carrier: res.data.carrier.id,
                }));
                setValues((values) => ({
                    ...values,
                    trackingnumber: res.data.shipping_info.tracking_number,
                }));
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const updateShippingInfo = async () => {
        axios.post(`myitem_shipping_info/${itemId}`, {
            "shipping_carrier": values.carrier,
            "tracking_number": values.trackingnumber
        }).then((res) => {
            swal({
                title: "Tracking info updated",
                icon: "success",
                button: "OK",
            }).then((value) => {
                props.history.push("/myaccount/myclaimeditems");
            });
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        if (!itemId) {
            props.history.push("/myaccount");
        }
        getShippings();
    }, []);

    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>Update Shipping Info</h2>
                </div>
                <div class="p-5">
                    <div class="form-group row">
                        <label className="col-3 control-label text-right" for="carrier">Carrier: </label>
                        <div className="col-9">
                            <select className="form-control form_input" name="carrier" id="carrier" onChange={update}>
                                {shippings.filter(item => !item.name.includes("Other")).filter(item => !item.name.includes("Local")).map((item, index) => {
                                    return <option
                                        value={item.id}
                                        selected={item.id == values.carrier ? "selected" : null}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-3 control-label text-right" htmlFor="tracking_number">Tracking
                            Number: </label>
                        <div className="col-9">
                            <input type="text" className="form-control form_input" placeholder="Tracking Number"
                                   name="trackingnumber" id="trackingnumber" value={values.trackingnumber}
                                   onChange={update}/>
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <button className="btn btn-blue" onClick={updateShippingInfo}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShippingDetails;
