import axios from "axios";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import swal from "sweetalert";
import DataTable from "react-data-table-component";

const customStyles = {
    header: {
        style: {
            minHeight: "56px",
        },
    },
    headRow: {
        style: {
            backgroundColor: "#e8ebf1",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "#c2c2c2",
            ":first-of-type": {
                borderLeftColor: "#c2c2c2",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
            },
        },
    },
    headCells: {
        style: {
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRightStyle: "solid",
            borderRightWidth: "2px",
            // borderRightColor: "white",
            borderRightColor: "rgb(194, 194, 194)",

            ":first-of-type": {
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
                borderLeftColor: "white",
            },
        },
    },
    cells: {
        style: {
            borderRightStyle: "solid",
            fontSize: "15px",
            borderRightWidth: "1px",
            borderRightColor: "#c2c2c2",
            borderBottomColor: "#c2c2c2",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            ":first-of-type": {
                borderLeftColor: "#c2c2c2",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
            },
        },
    },
};

function ClaimedItems(props) {

    const dateSort = (rowA, rowB) => {
        let dateA = new Date(rowA.date);
        let dateB = new Date(rowB.date);
        if (dateA > dateB) {
            return 1;
        }
        if (dateB > dateA) {
            return -1;
        }
        return 0;
    };
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const columns = [
        {
            name: "Date Claimed",
            selector: (row) => row.date,
            sortable: true,
            wrap: true,
            sortFunction: dateSort
        },
        {
            name: "Item ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Item Title",
            selector: (row) => row.name,
            sortable: true,
            //compact: true,
            wrap: true,
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => row.status !== "Received",
                    style: row => ({ color: row.status !== "Received" ? 'red' : 'inerit' }),
                },
            ],
            cell: (items) => {
                return (
                    <div>
                        {items.status}
                        {items.status === "Shipped" && items.shipping_info ? (
                            <div>
                                <a class="text_black" href={`/item-details/${items.id}`}> Tracking Info</a>
                            </div>
                        ) : null}
                    </div>
                )
            }
        },
        {
            name: "Actions",
            key: "action",
            text: "ActionS",
            className: "action",
            align: "left",
            sortable: false,
            wrap: false,
            width: "210px",
            cell: (items) => {
                return (
                    <div
                        style={{width: "100%", display: "flex", flexDirection: "column"}}
                    >
                        {items.status == "Pending" || items.status == "Received" ? (
                            <div
                                className="col-12"
                                onClick={() => pushToDetail(items.id)}
                                style={{marginTop: "5px", marginBottom: "5px"}}
                            >
                                <button
                                    className="btn btn_small_green w-100 btn-blue-hover"
                                    style={{paddingRight: "1px", paddingLeft: "1px"}}
                                >
                                    View
                                </button>
                            </div>
                        ) : (
                            <div
                                className="col-12"
                                onClick={() => pushToDetail(items.id)}
                                style={{marginTop: "5px"}}
                            >
                                <button
                                    className="btn btn_small_green w-100 btn-blue-hover"
                                    style={{
                                        paddingRight: "1px",
                                        paddingLeft: "1px",
                                        marginTop: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    View
                                </button>
                            </div>
                        )}

                        {(items.status !== 'Received') ? (
                            <div
                                className="col-12"
                                style={{marginBottom: "5px"}}
                            >
                                <button className="btn btn_small_green w-100 btn-blue-hover" onClick={() => {
                                    history.push(`/myaccount/my-messages/${items.product_user_id}/${items.id}`)
                                }}>
                                    Send Message
                                </button>
                            </div>
                        ) : null}

                        {items.status == "Shipped" ? (
                            <div className="col-12" style={{marginTop: "5px", marginBottom: "10px"}}>
                                <button
                                    className="btn btn_small_green w-100 btn-blue-hover"
                                    onClick={() => receivedStatus(items.id)}
                                    disabled={disabled}
                                    style={{
                                        paddingRight: "1px",
                                        paddingLeft: "1px",
                                    }}
                                >
                                    Received
                                </button>
                            </div>
                        ) : null}
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getItems();
    }, []);

    const getItems = () => {
        axios
            .get("items_claims")
            .then((res) => {
                console.log("Myclaimed : ", res.data);
                if (res.data.status == true) {
                    setProducts(res.data.data);
                    console.log("State Items are: ", products);
                }
            })
            .catch((err) => console.log(err));
        console.groupEnd("My List claimed");
    };

    const pushToDetail = (productId) => {
        const claimed = "claimed";
        history.push({pathname: `/item-details/${productId}`});
    };

    const receivedStatus = (productId) => {
        console.log("clicced", productId);
        setDisabled(true);
        axios
            .get(`/myitem_recieve/${productId}`)
            .then((data) => {
                console.log(data);
                swal({
                    title: "Awesome! Thank You",
                    icon: "success",
                    button: "OK",
                }).then(() => getItems());
            })
            .catch((error) => {
                console.log("error my claimeditems", error);
                swal({
                    title: "Error",
                    icon: "error",
                    button: "OK",
                });
            });
    };
    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>Items That I Claimed</h2>
                </div>
                <div className="transaction_outer mt-50">
                    {products.length == 0 ? (
                        <div
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <h4>No claimed Items found</h4>
                        </div>
                    ) : (
                        <DataTable
                            responsive={false}
                            columns={columns}
                            data={products}
                            highlightOnHover={true}
                            customStyles={customStyles}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default ClaimedItems;
