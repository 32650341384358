import React, {useState} from 'react'

import axios from 'axios'
import swal from "sweetalert";
import {useDispatch} from "react-redux";
import {fetchUserSuccess} from "../Redux";
import AdSense from "react-adsense";


export default function Login(props) {
    const [value, setValue] = useState({
        email: "",
        password: ""
    })
    const dispatch = useDispatch();
    const [error, setError] = useState({})
    const [emailError, setEmailError] = useState("")
    const [passwordError, setpasswordError] = useState("")
    const [loginError, setLoginError] = useState("")

    const handleSubmit = async (e) => {
        setpasswordError("")
        setEmailError("")
        setLoginError("")
        if (!value.email) {
            setLoginError("")
            setEmailError("Please enter your email address")
        }
        if (!value.password) {
            setLoginError("")
            setpasswordError("Please enter your password")
        }
        if (value.email && value.password) {
            try {
                const res = await axios({
                    method: 'post',
                    url: "login",
                    data: {
                        email: value.email,
                        password: value.password
                    }
                })
                var payload = res.data.user;
                var newUser = res.data.user
                axios.defaults.headers.common['Authorization'] = "Bearer " + res.data.token
                newUser.token = res.data.token
                localStorage.setItem('token', '' + res.data.token);
                localStorage.setItem('user', '' + JSON.stringify(newUser));
                dispatch(fetchUserSuccess(payload));
                console.log("token", localStorage.getItem('token'));
                swal({
                    title: "You Successfully Logged In",
                    icon: "success",
                    button: "OK",
                })

                props.history.replace("myaccount/dashboard");
                //window.location.href = '/'
                console.log("res", res);
                setValue({
                    email: "",
                    password: ""
                })

            } catch (err) {
                console.log("errrr", err);
                console.log(err.response.data.message);
                setpasswordError("")
                setEmailError("")
                if (err.response?.data?.message)
                    setLoginError(err.response.data.message)
            }
        }
    }
    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
        console.log({value});
    }
    const EnterKey = (e) => {
        if (e.keyCode === 13) {
            console.log('enter')
            handleSubmit()
        } else {
            console.log("no");
        }
    }

    return (
        <section className="mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-3 float-right mb-5">
                        <AdSense.Google
                            client='ca-pub-1905261535699460'
                            slot='6067654677'
                            style={{width: 260, height: 600, float: 'right'}}
                            format=''
                        />
                    </div>
                    <div className="col-6 mx-auto">
                        <div className="row">
                            <div className="login_sub_title mx-auto mb-4">
                                <h2>Log in</h2>
                            </div>
                        </div>
                        <div className="row">
                            <form className="mx-auto">
                                <div className="form_group d-flex">
                                    <i className="fa fa-envelope form_input pt-3" aria-hidden="true"></i>
                                    <input type="email" onKeyDown={(e) => EnterKey(e)}
                                           className="form-control form_input" placeholder="Enter Email"
                                           type="email" name="email" value={value.email}
                                           onChange={handleChange}/>

                                </div>
                                <p style={{color: "red"}}>{emailError}</p>
                                <div className="form_group mb-2 d-flex">
                                    <i className="fa fa-lock form_input px-4 pt-3" aria-hidden="true"></i>
                                    <input type="password" onKeyDown={(e) => EnterKey(e)}
                                           className="form-control form_input"
                                           placeholder="Enter Password"
                                           type="password" name="password" value={value.password}
                                           onChange={handleChange}/>
                                </div>
                                <p style={{color: "red"}}>{passwordError}</p>
                                <p style={{color: "red"}}>{loginError}</p>
                                <a href="" onClick={() => props.history.push({pathname: "/forgotpassword"})}>
                                    <p className="float-right lead text_orange f_w_500 ">Forget Your Password?</p>
                                </a>
                                <div className="form-check form-check-inline mt-5 mb-3">
                                    <input className="form-check-input" type="checkbox"/>
                                    <label className="form-check-label ml-1">Remember</label>
                                </div>
                                <button type="button" className="btn btn-blue w-50 mb-3" onClick={handleSubmit}>Login</button>
                                <p className="mb-5">Don't have an account? <a href="/Signup"> <span className=" text_orange font-weight-bold" onClick={() => props.history.push({pathname: "/login"})}>Sign Up</span></a></p>
                            </form>
                        </div>
                    </div>
                    <div className="col-3 float-left mb-5">
                        <AdSense.Google
                            client='ca-pub-1905261535699460'
                            slot='2128409668'
                            style={{width: 260, height: 600, float: 'left'}}
                            format=''
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
