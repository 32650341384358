import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

import { fetchUserRemove, fetchTotalProducts } from "../../Redux";
import ItemDetails from "../../Screens/ItemDetails";

function HeaderTop() {
  useEffect(() => {
    dispatch(fetchTotalProducts());
    fetchsetting();
  }, []);
  const history = useHistory();
  const dispatch = useDispatch();
  const [setting, setSetting] = useState([]);

  //const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const totalProducts = useSelector((state) => state.user.totalProducts);
  const fetchsetting = async () => {
    var res = await axios.get("setting");
    console.log("footer Links", res.data.data);
    setSetting(res.data.data);
    document.title = res.data.data[0].title_bar;
  };
  const logout = async () => {
    dispatch(fetchUserRemove());
    try {
      const logOut = await axios.get("logout");
      console.log({ logOut });
    } catch (error) {
      console.log(error);
    }
    swal({
      title: "You Successfully Logged Out",
      icon: "success",
      button: "OK",
    });
    history.replace({ pathname: "/" });
  };

  return (
    <div class="header_inner">
      <div className="header_top">
        <div className="container">
          <div className="header_top_box">
            <div className="header_top_left d-align">
              <a
                href={`mailto:${setting.map((i) =>
                  i.email ? i.email : null
                )}`}
                className="header_email"
              >
                <img src="/images/icon-envelope.png" alt="" />
                {setting.map((item) => {
                  return item.email ? <span>{item.email}</span> : null;
                })}
                {/* <span className="Underline">customerservice@justacredit.com</span> */}
              </a>
              <div className="header_claiming_text" style={{ marginLeft: '260px' }}>
                <p>
                  Currently there are <span>{totalProducts}</span>{" "}
                  <strong>items for claiming</strong>
                </p>
              </div>
            </div>
            <div className="header_socail">
              {isLoggedIn ? (
                <div>
                  <a
                    onClick={() =>
                      history.push({ pathname: "/myaccount/dashboard" })
                    }
                    className="btn btn-blue btn-sm"
                  >
                    My Account
                  </a>
                  &nbsp;&nbsp;
                  <a onClick={() => logout()} className="btn btn-blue btn-sm">
                    Log Out
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    onClick={() => history.push({ pathname: "/Login" })}
                    className="btn btn-blue btn-sm"
                  >
                    Log In
                  </a>
                  &nbsp;&nbsp;
                  <a
                    onClick={() => history.push("/SignUp")}
                    className="btn btn-blue btn-sm"
                  >
                    Sign Up
                  </a>
                </div>
              )}
              {/* {isLoggedIn ? <div><a onClick={() => history.push({ pathname: '/' })} className="btn btn-blue btn-sm" >My Account</a>&nbsp;&nbsp;<a onClick={() => logout()} className="btn btn-blue btn-sm">Logout</a></div> :
                                <div><a onClick={() => history.push({ pathname: '/Login' })} className="btn btn-blue btn-sm" >Log In</a>&nbsp;&nbsp;<a onClick={() => history.push('/SignUp')} className="btn btn-blue btn-sm">Sign Up</a></div>} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderTop;
