import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
function MyListedItemsClaimed() {
  const user = useSelector((state) => state.user.user);

  const history = useHistory();
  const [allChats, setAllChats] = useState([]);
  const [myListedItems, setMyListedItems] = useState([]);
  const [othersListedItems, setOthersListedItems] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.post("getUsersAllChatRoom");
      if (response.data.status) {
        var myItems = response.data.data.filter(
          (item) => item.product.user_id == user.id
        );
        var othersItems = response.data.data.filter(
          (item) => item.product.user_id != user.id
        );
        setMyListedItems(myItems);
        setOthersListedItems(othersItems);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="account_content">
        <div className="acco_box">
          <div className="sub_title">
            <h2>Chats - My Listed Items</h2>
          </div>

          <ul className="panel-body">
            <ul className="chat">
              {myListedItems.map((item) => (
                <li className="mb-2 mt-2" key={item.id}>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      paddingRight: "15px",
                      paddingLeft: "10px",
                    }}
                  >
                    {item.room_participants.filter(
                      (singleUser) => singleUser.user_id != user.id
                    ).length ? (
                      <h4>
                        {
                          item.room_participants.filter(
                            (singleUser) => singleUser.user_id != user.id
                          )[0]?.user.name
                        }{" "}
                        - {item.product.name}
                      </h4>
                    ) : (
                      <h4>
                        {user.name} - {item.product.name}
                      </h4>
                    )}
                    <p
                      className="hoverColor"
                      onClick={() =>
                        history.push(
                          `/myaccount/my-chats/${item.product.id}?cid=${item.id}`
                        )
                      }
                    >
                      Go To Chat
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </ul>
        </div>

        <div className="acco_box mt-5">
          <div className="sub_title">
            <h2>Chats - Items I Am Interested In</h2>
          </div>

          <ul className="panel-body">
            <ul
              className="chat"
              style={{
                paddingRight: "15px",
                paddingLeft: "10px",
              }}
            >
              {othersListedItems.map((item) => (
                <li className="mb-2 mt-2" key={item.id}>
                  <div className="d-flex justify-content-between">
                    <h4>
                      {
                        item.room_participants.filter(
                          (singleUser) => singleUser.user_id != user.id
                        )[0].user.name
                      }{" "}
                      - {item.product.name}
                    </h4>
                    <p
                      className="hoverColor"
                      onClick={() =>
                        history.push(
                          `/myaccount/my-chats/${item.product.id}?cid=${item.id}`
                        )
                      }
                    >
                      Go To Chat
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </ul>
        </div>
      </div>
    </>
  );
}

export default MyListedItemsClaimed;
