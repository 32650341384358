import React, { useState, useEffect } from 'react'
import axios from 'axios'

export default function PrivacyPolicy() {

    const [aboutdata, setAboutData] = useState([])

    const fetchaboutus = async () => {
        var res = await axios.get('pages')
        console.log('PrivacyPolicy', res)
        setAboutData(res.data.data)
    }


    useEffect(() => {
        fetchaboutus()
    }, [])

    return (<div>
        <section className="about_outer">
            <div className="container my-5">
                <div className="title text-center mb-5">
                    <h2>{aboutdata.length !== 0 ? <h2>{aboutdata[3].title}</h2> : ""}</h2>
                </div>
                <div className="row   ">
                    <div className="col-12">
                        <p>{aboutdata.length !== 0 ? <p dangerouslySetInnerHTML={{ __html: aboutdata[3].description }} /> : ""}</p>
                    </div>
                </div>
            </div>
        </section>


    </div>)
}