import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import axios from "axios";
import GoogleAd from "../../Ads/GoogleAd";
import AdSense from "react-adsense";

export default function Footer() {
    const [setting, setSetting] = useState([]);

    const fetchsetting = async () => {
        var res = await axios.get("setting");
        console.log("footer Links", res.data.data);
        setSetting(res.data.data);
        // console.log("setting", res.data.data)
    };

    useEffect(() => {
        fetchsetting();
    }, []);


    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // For a smooth scrolling
        });
      };
      

    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    return (
        <div>
            <footer>
                <div className="footer_top ">
                    <div className="container">
                        <div className="footer_top_box d-new">
                            <div className="footer_logo_box">
                                <div className="footer_logo">
                                    {setting.map((items, index) => {
                                        return <img src={items.website_logo}/>;
                                    })}
                                </div>
                                <div className="deliver_partner">
                                    <h6>Delivery Partners</h6>
                                    <ul>
                                        <li>
                                            <img src="/images/deliver_partner_01.png" alt=""/>
                                        </li>
                                        <li>
                                            <img src="/images/deliver_partner_02.png" alt=""/>
                                        </li>
                                        <li>
                                            <img src="/images/deliver_partner_03.png" alt=""/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer_link">
                                <h4>Useful Links</h4>
                                <ul className="link_list">
                                    <li>
                                        <Link to="/about" onClick={scrollToTop}>About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/HowItWorks" onClick={scrollToTop}>How It Works</Link>
                                    </li>
                                    <li>
                                        <Link to="/PrivacyPolicy" onClick={scrollToTop}>Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms" onClick={scrollToTop}>Terms and Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/ContactUs" onClick={scrollToTop}>Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer_link">
                                <h4>Member Tools</h4>
                                <ul className="link_list">
                                    <li>
                                        <Link to="/myaccount/dashboard" onClick={scrollToTop}>My Account</Link>
                                    </li>
                                    <li>
                                        <Link to="/current-items" onClick={scrollToTop}>Current Items</Link>
                                    </li>
                                    {!isLoggedIn ? (
                                        <li>
                                            <Link to="/signup" onClick={scrollToTop}>Sign Up</Link>
                                        </li>
                                    ) : null}
                                    {/* <li><Link to='/BuyCredits'>Sell Credits</Link></li> */}
                                </ul>
                            </div>

                            {setting.map((items, index) => {
                                return (
                                    <div className="footer_link footer_link_last">
                                        <h4>Get in Touch</h4>
                                        <div className="footer_email">
                                            <a href={`mailto:${items.email}`}>
                                                {/* <a href="mailto:customerservice@justacredit.com"> */}
                                                <img src="/images/icon-envelope-white.png" alt=""/>
                                                <span>{items.email}</span>
                                            </a>
                                        </div>
                                        <ul className="footer_socail" style={{marginTop: 25}}>
                                            <li>
                                                <a href={items.facebook} target="_blank">
                                                    <img src="/images/icon-facebook-white.png" alt=""/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={items.youtube} target="_blank">
                                                    <img src="/images/icon-youtube-white.png" alt=""/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div class="row mb-4">
                            <div class="col-2"></div>
                            <div class="col-10 mx-auto">
                                <AdSense.Google
                                    client='ca-pub-1905261535699460'
                                    slot='7635639799'
                                    style={{ width: 970, height: 90, float: 'left' }}
                                    format=''
                                />
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <div className="text-center">
                            {setting.map((item) => {
                                return item.footer_copy_right ? (
                                    <p>{item.footer_copy_right}</p>
                                ) : null;
                            })}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
