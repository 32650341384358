import axios from "axios";
import React, {useEffect, useState} from "react";
import {useParams, useHistory, useLocation} from "react-router";
import {useSelector} from "react-redux";
import swal from "sweetalert";

const ItemDetails = (props) => {
    const [disabled, setDisabled] = useState(false);
    const location = useLocation();
    const [credit, setCredit] = useState("");
    const getCredit = async () => {
        try {
            const res = await axios.get("token");
            if (res.data.status == true) {
                setCredit(res.data.response.credits);
            }
            console.log("TOKEN", res);
        } catch (error) {
            console.log("error");
        }
    };
    const user = useSelector((state) => state.user.user);
    const history = useHistory();
    const [isFetched, setIsFetched] = useState(false);
    const [product, setProduct] = useState({});
    const [shippings, setShippings] = useState([]);
    const [shippingInfo, setShippingInfo] = useState({});
    const {itemId} = useParams();

    useEffect(() => {
        getCredit();
        console.log("useEffect product is: ", product);
        console.log("LOCATION", props);
    }, []);

    useEffect(() => {
        if (!itemId) {
            props.history.push("/myaccount");
        }
        axios
            .get(`product_details/${itemId}`)
			
            .then((res) => {
				console.log('tarek3'+res);
                setProduct(res.data.data);
                setProduct((prevState) => ({
                    ...prevState,
                    images: res.data.image.map((item) => item.image),
                }));
                setProduct((prevState) => ({
                    ...prevState,
                    images: [prevState.primary_image, ...prevState.images],
                }));
                setShippingInfo(res.data.shipping_info);
                setShippings(res.data.shipping);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsFetched(true);
                console.log("is fetched now: ", isFetched);
            });
    }, []);

    const noCreditAlert = () => {
        swal({
            title: "You need a credit to claim this item",
            icon: "error",
            button: "OK",
        });
    };

    const goToBuyCredits = () => {
        history.push('/buycredits');
        window.scrollTo(0, 0); // Scroll to the top of the page
      };

    const userIsClaimer = (product) => {
        if (product.claimed) {
            return product.claimed.user_id === user.id
        }
        return false;
    }

    const ClaimProduct = async () => {
		
		        swal({
            title: "Please confirm claim",
            text: "",
            icon: "success",
            buttons: true,
            dangerMode: true,
			allowOutsideClick: false,
			buttons: ["Cancel claim", "Confirm claim"],
        }).then((willDelete) => {
            if (willDelete) {
                      setDisabled(true);
        const data = {
            product_id: parseInt(itemId),
        };
       
            const res = axios.post("product_claims", data);
            console.log("claim product", res);
            swal({
                title: "Item Claimed",
                icon: "success",
                button: "OK",
            }).then(() => {
                window.location.href = "/myaccount/claimeditems";
                // history.push('/current-items')
            });

            } else {
                swal({
                    title: "Claim was canceled",
					
                    icon: "error",
                });
            }
        });
		
		
		
		
		
		
		
		

    };

    const swapPrimaryImage = (image, index) => {
        var tempImages = JSON.parse(JSON.stringify(product.images));
        setProduct((prevState) => ({
            ...prevState,
            primary_image: image,
            images: tempImages,
        }));
    };

    return (
        <div>
            <section className="product_details_outer mt-70">
                {product.id ? (
                    <div className="container">
                        <div className="product_content">
                            <div className="product_details_img">
                                <div className="product_thum">
                                    <div className="product_view_thum_slider">
                                        {product.images &&
                                            product.images.map((image, index) => (
                                                <div
                                                    className="item"
                                                    key={"image" + index}
                                                    onClick={() => swapPrimaryImage(image, index)}
                                                >
                                                    <div className="product_thum_box">
                                                        <img
                                                            src={image}
                                                            data-img="images/product_big_thum_01.png"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="product_big_img">
                                    <img src={product.primary_image} alt=""/>
                                </div>
                            </div>
                            <div className="product_details">
                                <h1>{product.name}</h1>
                                <div className="product_att">
                                    <h4>Brief Description</h4>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: product.description.replace(/\&nbsp;/g, '').slice(0, 50) + "...",
                                        }}
                                    />
                                </div>
                                <div className="product_att">
                                    <h4>Category / Sub Category</h4>
                                    <p>
                                        {product.category} / {product.subcategory}
                                    </p>
                                </div>
                                <div className="product_att">
                                    <h4>Member</h4>
                                    <p>
                                        {product.username}(
                                        <span style={{color: "orange", fontWeight: "bold"}}>
                                          {product.userratings}
                                        </span>
                                        )
                                        {user.id && user.id != product.user_id && product.status == 1 ? (
                                            <>
                                                <button class="btn btn-blue btn-sm"
                                                        style={{marginLeft: "40px"}}
                                                        onClick={() =>
                                                            history.push(`/myaccount/my-messages/${product.user_id}/${itemId}`)
                                                        }
                                                >
                                                    Send Message
                                                </button>
                                            </>
                                        ) : null}
                                    </p>
                                </div>

                                <div className="product_att">
                                    <h4>Shipping</h4>
                                    <p>
                                        {shippings.map((item, index) => {
                                            return index == shippings.length - 1
                                                ? item.shipping
                                                : item.shipping + ", ";
                                        })}
                                    </p>
                                </div>

                                {shippingInfo && (userIsClaimer(product) || (product.user_id == user.id)) ? (
                                    <div className="product_att">
                                        <h4>Tracking Info</h4>
                                        <p>{ shippingInfo.carrier ? shippingInfo.carrier.name : null} Tracking #{shippingInfo.tracking_number}</p>
                                    </div>
                                ) : null}

                                {
                                    (product.status == 1 && user.id) ?
                                        credit == 0 ?
                                            (<div className="product_claim_btn">
                                                    <button className="btn btn-blue btn-sm"
                                                            onClick={goToBuyCredits}>Buy Credits
                                                    </button>
                                                    <br/>
                                                    <div style={{color: 'red', paddingTop: '20px'}}>
                                                        <p>Your credit balance is 0. You need a credit to claim this
                                                            item.</p>
                                                    </div>
                                                </div>
                                            ) :
                                            user.id != product.user_id && (
                                                <div className="product_claim_btn">
                                                    <button className="btn btn-blue btn-sm" onClick={ClaimProduct}
                                                            disabled={disabled}>Claim Item
                                                    </button>
                                                </div>
                                            ) : null
                                }
                            </div>
                        </div>
                        <div className="product_description">
                            <h3>Full Description</h3>
                            <div dangerouslySetInnerHTML={{__html: product.description}}/>
                        </div>
                    </div>
                ) : (
                    <div className="container d-flex justify-content-center">
                        <div> {isFetched && "No Product Found"}</div>
                    </div>
                )}
            </section>
        </div>
    );
};

export default ItemDetails;
