import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import {fetchTotalProducts} from "../../Redux";
import DataTable from "react-data-table-component";

const customStyles = {
    header: {
        style: {
            minHeight: "56px",
        },
    },
    headRow: {
        style: {
            backgroundColor: "#e8ebf1",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "#c2c2c2",
            ":first-of-type": {
                borderLeftColor: "#c2c2c2",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
            },
        },
    },
    headCells: {
        style: {
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRightStyle: "solid",
            borderRightWidth: "2px",
            borderRightColor: "white",
            borderRightColor: "rgb(194, 194, 194)",
            ":first-of-type": {
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
                borderLeftColor: "white",
            },
        },
    },
    cells: {
        style: {
            borderRightStyle: "solid",
            fontSize: "15px",
            borderRightWidth: "1px",
            borderRightColor: "#c2c2c2",
            borderBottomColor: "#c2c2c2",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            ":first-of-type": {
                borderLeftColor: "#c2c2c2",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
            },
        },
    },
};

const MyItems = (props) => {

    const dateSort = (rowA, rowB) => {
        let dateA = new Date(rowA.date);
        let dateB = new Date(rowB.date);
        if (dateA > dateB) {
            return 1;
        }
        if (dateB > dateA) {
            return -1;
        }
        return 0;
    };
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const columns = [
        {
            name: "Date Listed",
            selector: (row) => row.date,
            sortable: true,
            sortFunction: dateSort
        },
        {
            name: "Item ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Item Title",
            selector: (row) => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Actions",
            key: "action",
            text: "Actions",
            className: "action",
            align: "left",
            sortable: false,
            width: "300px",
            cell: (data) => {
                return (
                    <>
                        <button
                            className="btn text-white btn_small_green w-100 my-2 btn-blue-hover"
                            style={{marginLeft: "1px"}}
                            onClick={() => {
                                pushToDetail(data.id);
                            }}
                        >
                            View
                        </button>
                        <button
                            className="btn text-white btn_small_green w-100 my-2 btn-blue-hover"
                            style={{marginLeft: "1px"}}
                            onClick={() => {
                                pushToEdit(data.id);
                            }}
                        >
                            Edit
                        </button>
                        <button
                            className="btn text-white btn_small_green w-100 my-2 btn-blue-hover"
                            style={{marginLeft: "1px"}}
                            onClick={() => {
                                deleteItem(data.id);
                            }}
                        >
                            Delete
                        </button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        console.group("My List Group");
        axios
            .get("myitem")
            .then((res) => {
                console.log("My List data : ", res.data.data);
                if (res.data.status == true) {
                    setProducts(res.data.data);

                    console.log("State Items are: ", products);
                }
            })
            .catch((err) => console.log(err));
        console.groupEnd("My List Group");
    }, []);

    const deleteItem = (productId) => {
        swal({
            title: "Are you sure you want to delete this item?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.get(`myitem_delete/${productId}`).then((res) => {
                    if (res.data.status == true) {
                        setProducts((prevState) =>
                            prevState.filter((item) => item.id != productId)
                        );
                        // window.location.href = '/myaccount/my-list'
                        dispatch(fetchTotalProducts());

                        swal({
                            title: "Your item has been deleted successfully",
                            icon: "success",
                        });
                    } else {
                        swal({
                            title: "Your item is not deleted!",
                        });
                    }
                });
            } else {
                swal({
                    title: "Your item is not deleted!",
                });
            }
        });
    };

    const pushToDetail = (productId) => {
        props.history.push({pathname: `/item-details/${productId}`});
    };

    const pushToEdit = (productId) => {
        props.history.push({pathname: `/myaccount/edit-item/${productId}`});
    };

    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>My Listed Items</h2>
                </div>
                <div className="transaction_outer mt-50">
                    {products.length == 0 ? (
                        <div
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <h4>You have not added any items yet</h4>
                        </div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={products}
                            highlightOnHover={true}
                            customStyles={customStyles}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyItems;
{
    /* <div className="table_outer">
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>Item Id</th>
        <th>Item Title</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {products.map((product) => (
        <tr key={product.id}>
          <td data-label="Item ID">{product.id}</td>
          <td data-label="Item Title">{product.name}</td>
          <td data-label="Action">
            <div className="row">
              <div
                className="col-sm-4"
                onClick={() => pushToDetail(product.id)}
              >
                <button className="btn text-white btn_small_green w-100 my-2">
                  View
                </button>
              </div>
              <div
                className="col-sm-4"
                onClick={() => pushToEdit(product.id)}
              >
                <button className="btn text-white btn_small_green w-100 my-2">
                  Edit
                </button>
              </div>
              <div
                className="col-sm-4"
                onClick={() => deleteItem(product.id)}
              >
                <button className="btn text-white btn_small_green w-100 my-2">
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  </div> */
}
