import React, {useState, useEffect} from "react";
import {useHistory} from "react-router";
import axios from "axios";
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import AdSense from "react-adsense";

function LatestItems() {
    const history = useHistory();
    const pushToDetail = (productId) => {
        history.push({pathname: `/item-details/${productId}`});
    };
    const [products, setProducts] = useState([]);

    useEffect(() => {
        console.log("LATEST", products);
        axios
            .get("get_product")
            .then((res) => {
                if (res.data.status == true && res.data.data.data?.length) {
                    console.log("Products are: ", res.data.data);
                    setProducts(res.data.data.data);
                    console.log("New Product Array: ", products);
                }
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <section className="latest_item_outer">
            <div className="container">
                <div className="title text-center">
                    <h2 className="mb-4">Latest Items</h2>
                </div>
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={150}
                    totalSlides={products.length}
                    visibleSlides={5}
                    isPlaying={true}
                    interval={2000}
                >
                    <div className="carousel_div">

                        <ButtonBack className="sliderButtons">&#60;</ButtonBack>

                        <Slider className="slider_div">
                            {products.map((item, index) => (
                                <Slide index={index}>
                                    <div
                                        className="item"
                                        style={{marginLeft: "10px", marginRight: "10px", cursor: 'pointer'}}
                                        onClick={() => pushToDetail(item.id)}
                                    >
                                        <div className="latest_item">
                                            <div className="latest_item_img">
                                                <img src={item.primary_image} alt=""/>
                                            </div>
                                            <h3>{item.name}</h3>
                                        </div>
                                    </div>
                                </Slide>
                            ))}
                        </Slider>

                        <ButtonNext className="sliderButtons">&#62;</ButtonNext>

                    </div>
                </CarouselProvider>
            </div>
            <div class="container margin-t-20">
                <div class="row align-items-center">
                    <AdSense.Google
                        client='ca-pub-1905261535699460'
                        slot='2189018179'
                        style={{width: 970, height: 90, margin:"auto"}}
                        format=''
                    />
                </div>
            </div>
        </section>
    );
}

export default LatestItems;
