
import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";

export default function Protected(props) {

    let Component = props.Component
    let history = useHistory();
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/login')
        }
    }, [])

    return (<div>
        <Component />
    </div>)
}

// import React, { useEffect } from 'react'
// import { useHistory } from "react-router-dom";

// export default function Protected(props) {

//     let Component = props.Component
//     let history = useHistory();
//     useEffect(() => {
//         if (!localStorage.getItem('token')) {
//             history.push('/login')
//         }
//     }, [])

//     return (<div>
//         <Component />
//     </div>)
// }