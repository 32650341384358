import axios from "axios";
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import DataTable from "react-data-table-component";
// const customStyles = {

//   header: {
//     style: {
//       minHeight: "56px",
//     },
//   },
//   headRow: {
//     style: {
//       backgroundColor: "#e8ebf1",

//       style: {
//         backgroundColor: "#e8ebf1",
//         borderTopStyle: "solid",
//         borderTopWidth: "1px",
//         borderTopColor: "#c2c2c2",
//         ":first-of-type": {
//           borderLeftColor: "#c2c2c2",
//           borderLeftStyle: "solid",
//           borderLeftWidth: "1px",
//         },
//       },
//     },
//   },
//   headCells: {
//     style: {
//       fontSize: "17px",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       borderRightStyle: "solid",
//       borderRightWidth: "2px",
//       borderRightColor: "white",

//       ":first-of-type": {
//         borderLeftStyle: "solid",
//         borderLeftWidth: "1px",
//         borderLeftColor: "white",
//       },
//     },
//   },
//   cells: {
//     style: {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       borderRightStyle: "solid",
//       fontSize: "15px",
//       borderRightWidth: "1px",
//       borderRightColor: "#c2c2c2",
//       borderBottomColor: "#c2c2c2",
//       borderBottomStyle: "solid",
//       borderBottomWidth: "1px",
//       ":first-of-type": {
//         borderLeftColor: "#c2c2c2",
//         borderLeftStyle: "solid",
//         borderLeftWidth: "1px",
//       },
//     },
//   },
// };
const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#e8ebf1",
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#c2c2c2",
      ":first-of-type": {
        borderLeftColor: "#c2c2c2",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
      },
    },
  },
  headCells: {
    style: {
      fontSize: "17px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRightStyle: "solid",
      borderRightWidth: "2px",
      // borderRightColor: "white",
      borderRightColor: "rgb(194, 194, 194)",

      ":first-of-type": {
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "white",
      },
    },
  },
  cells: {
    style: {
      borderRightStyle: "solid",
      fontSize: "15px",
      borderRightWidth: "1px",
      borderRightColor: "#c2c2c2",
      borderBottomColor: "#c2c2c2",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      ":first-of-type": {
        borderLeftColor: "#c2c2c2",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
      },
    },
  },
};
function Transactions() {
  const [data, setData] = useState([]);
  console.log("Userdata", data[0], data[1]);
  const transactionData = async () => {
    try {
      const res = await axios.get("transaction");
      console.log("transactions", res);
      if (res.data.status == "true") {
        console.log("transactions");
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Item ID",
      selector: (row) => row.Item_id,
      sortable: true,
    },
    {
      name: "Item Title",
      selector: (row) => row.item_title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Transaction Description",
      selector: (row) => row.description,
      sortable: true,
      width: "300px",
      wrap: "true",
    },
  ];
  useEffect(() => {
    transactionData();
  }, []);
  return (
    <div className="account_content">
      <div className="acco_box">
        <div className="sub_title">
          <h2>Transactions</h2>
        </div>
        <div className="transaction_outer mt-50">
          {data.length == 0 ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                display: "flex",
              }}
            >
              <h4>No Transaction</h4>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={data}
              highlightOnHover={true}
              customStyles={customStyles}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Transactions;

{
  /* <div className="table_outer">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Item ID</th>
                    <th>Item Title</th>
                    <th>Transaction Description</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((items, index) => {
                    return (
                      <tr key={items.id}>
                        <td data-label="Date">{items.date}</td>
                        <td data-label="Item ID">{items.Item_id}</td>
                        <td data-label="Item Title">{items.item_title}</td>
                        <td data-label="Item Title">{items.description}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */
}
