import React, { useState, useEffect } from "react";
import { Link, NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { fetchUserRemove } from "../../Redux";

function HeaderCenter(props) {
  const [active, isActive] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const logout = async () => {
    dispatch(fetchUserRemove());
    try {
      const logOut = await axios.get("logout");
      console.log({ logOut });
    } catch (error) {
      console.log(error);
    }
    swal({
      title: "You Successfully Logged Out",
      icon: "success",
      button: "OK",
    });
    history.replace({ pathname: "/Login" });
  };
  return (
    <div class="header_inner">
      <div className="header_center">
        <div className="container">
          <div className="header_center_box d-new-a">
            <div className="logo">
              <a href="/">
                <img src="/images/logo.png" alt="" />
              </a>
            </div>
            <div className="menu">
              <ul>
                {/* <li className='active'><a href="/" >Home</a></li> */}

                <li>
                  <NavLink to="/" exact activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" activeClassName="active">
                    About Us
                  </NavLink>
                </li>
                {/* <li ><a href='/about'>About US</a></li> */}
                <li>
                  <NavLink to="/howitworks" activeClassName="active">
                    How It Works
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/current-items" activeClassName="active">
                    Current Items
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/buycredits" activeClassName="active">
                    Buy Credits
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ContactUs" activeClassName="active">
                    Contact Us
                  </NavLink>
                </li>
                {/* <li
                  className="hide_desktop"
                  onClick={() => history.push({ pathname: "/Login" })}
                >
                  <a href="#">Login</a>
                </li>
                <li
                  className="hide_desktop"
                 onClick={() => history.push({ pathname: "/Signup" })}
                >
                  <a href="#">Signup</a>
                </li> */}
                {/* {isLoggedIn ? <div><a onClick={() => history.push({ pathname: '/myaccount/dashboard' })} className="btn btn-blue btn-sm" >My Account</a>&nbsp;&nbsp;<a onClick={() => logout()} className="btn btn-blue btn-sm">Logout</a></div> :

                                <div><a onClick={() => history.push({ pathname: '/Login' })} className="btn btn-blue btn-sm" >Log In</a>&nbsp;&nbsp;<a onClick={() => history.push('/SignUp')} className="btn btn-blue btn-sm">Sign Up</a></div>} */}

                {isLoggedIn ? (
                  <>
                    <li
                      className="hide_desktop"
                      onClick={() =>
                        history.push({ pathname: "/myaccount/dashboard" })
                      }
                    >
                      <a href="#">My Account</a>
                    </li>
                    <li className="hide_desktop" onClick={() => logout()}>
                      <a href="#">Logout</a>
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      className="hide_desktop"
                      onClick={() => history.push("/Login")}
                    >
                      <a href="#">Log in</a>
                    </li>
                    <li
                      className="hide_desktop"
                      onClick={() => history.push("/SignUp")}
                    >
                      <a href="#">Signup</a>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="toggleMenu">&#9776;</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderCenter;
