import axios from 'axios'
import Reactm, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import swal from "sweetalert";

const BuyStep2 = (props) => {
    const history = useHistory()

    const [paymentlink, setPaymentlink] = useState('')

    const creditdata = props.location.creditdata ? props.location.creditdata : {price: '', credits: ''}

    if(!props.location.creditdata) {
        history.replace('BuyCredits')
    }



    const handlesubmit = async () => {

        var body = {
            credit_id: creditdata.id,
            name: creditdata.title,
            description: creditdata.detail,
            price: creditdata.price

        }
        var res = await axios.post('payment', body)
        console.log("payment", res.data.link)
        console.log("payment", res.data.status)
        if (res.data.status == "true") {

            setPaymentlink(res.data.status)
            if (res.data?.link) {
                window.location.replace(res.data.link)
            } else {
                swal({
                    title: "Something went wrong!",
                    icon: "error",
                    button: "OK",
                })
            }
            // window.location.replace('https://www.sandbox.paypal.com/webscr?cmd=_express-checkout&token=EC-06B25995EC4244328')

        }
    }



    return (
        <section class="buy_credits_outer">
            <div class="container">
                <div class="row text-center">
                    <div class="col-12 pb-4 buy_credits_payment">
                        <p class="font-weight-bold">{creditdata.credits} Credit (s)</p>
                        <h3>${creditdata.price}</h3>
                    </div>
                    <div class="col-12 ">
                        <div class="row px-md-5  pb-5  ">
                            <div class="col-xl-4 offset-md-4 px-md-5 mb-5">
                                {/* <div class="payment_img mb-3" onClick={handlesubmit} >
                                    {paymentlink ? <a href="https://www.sandbox.paypal.com/webscr?cmd=_express-checkout&token=EC-06B25995EC4244328">
                                        <img src="./images/paypal.jpg" alt="" /></a> : <img src="./images/paypal.jpg" alt="" />}
                                </div> */}

                                <div class="payment_img mb-3" onClick={handlesubmit} >
                                    {paymentlink ? <a>
                                        <img src="./images/paypal.jpg" alt="" /></a> : <img src="./images/paypal.jpg" alt="" />}
                                </div>
                                <p> Click on the PayPal icon above to purchase</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BuyStep2
