import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Aboutus() {
  const [aboutdata, setAboutData] = useState([]);

  const fetchaboutus = async () => {
    var res = await axios.get("pages");
    console.log("aboutus", res);
    setAboutData(res.data.data);
  };

  useEffect(() => {
    fetchaboutus();
  }, []);

  return (
    <div>
      <section className="about_outer">
        <div className="container my-5">
          <div className="title text-center mb-5">
            <h2>
              {aboutdata.length !== 0 ? <h2>{aboutdata[0].title}</h2> : ""}
            </h2>
          </div>
          <div className="row">
            <div className="col-12">
              <p>
                {aboutdata.length !== 0 ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: aboutdata[0].description,
                    }}
                  />
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="work_outer">
        <div className="container">
          <div className="title text-center">
            <h2>
              {aboutdata.length !== 0 ? <h2>{aboutdata[1].title}</h2> : ""}
            </h2>
          </div>
          <div className="work_list">
            <div className="row">
              <div className="col-md-3 col-lg-3">
                <div className="work_item">
                  <div className="work_img">
                    <img src="/images/icon-work_01.png" alt="" />
                    <div className="work_number">1</div>
                  </div>
                  <p>Add Your Item</p>
                </div>
              </div>
              <div className="col-md-3 col-lg-3">
                <div className="work_item">
                  <div className="work_img">
                    <img src="/images/icon-work_02.png" alt="" />
                    <div className="work_number">2</div>
                  </div>
                  <p>Members Can Claim Your Item For Free</p>
                </div>
              </div>
              <div className="col-md-3 col-lg-3">
                <div className="work_item">
                  <div className="work_img">
                    <img src="/images/icon-work_03.png" alt="" />
                    <div className="work_number">3</div>
                  </div>
                  <p>Ship Item To Member</p>
                </div>
              </div>
              <div className="col-md-3 col-lg-3">
                <div className="work_item">
                  <div className="work_img">
                    <img src="/images/icon-work_04.png" alt="" />
                    <div className="work_number">4</div>
                  </div>
                  <p>You Earn One Credit To Claim Something You Want</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
