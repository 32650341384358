import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {fetchLocalUser} from "../../Redux";
import swal from "sweetalert";

const Message = (props) => {

    const dispatch = useDispatch()
    const history = useHistory();
    const user = useSelector((state) => state.user.user);

    const [message, setMessage] = useState({
        id: null,
        from_user_id: null,
        message: "",
        from: {
            name: ""
        },
        product: {
            name: ""
        }
    });

    const [newMessage, setNewMessage] = useState({
        newMessage: ""
    });

    useEffect(async () => {
        await mounted();
    }, []);

    const mounted = async () => {
        await getMessage()
    };

    const getMessage = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`message/${props.match.params.mid}`);
                setMessage(response.data)
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Send message to owner of product.
     * @returns {Promise<void>}
     */
    const sendMessage = async () => {

        if(newMessage.newMessage == ""){
            swal({
                title: "You must enter a message!",
                icon: "error",
                button: " OK",
            })
            return;
        }

        await axios.post('message/send', {
            "to": message.from_user_id,
            "body": newMessage.newMessage,
            "product": message.product.id
        }).then(res => {
                dispatch(fetchLocalUser())
                swal({
                    title: "Message sent successfully",
                    icon: "success",
                    button: " OK",
                }).then(() => {
                    history.push(`/myaccount/my-messages`)
                })
            }
        ).catch(err => {
            swal({
                title: "Error",
                icon: "error",
                button: " OK",
            })
        });
    }

    /**
     *
     * @param message
     */
    const updateMessage = (message) => {
        setNewMessage((prevState) => ({
            ...prevState,
            newMessage: message
        }));
    }

    const deleteMessage = () => {
        return new Promise(async (resolve, reject) => {
            try {
                await axios.delete(`message/${message.id}`);
                swal({
                    title: "Message deleted successfully",
                    icon: "success",
                    button: " OK",
                }).then(() => {
                    history.push(`/myaccount/my-messages`)
                })
            } catch (error) {
                reject(error);
            }
        });
    };

    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>Message</h2>
                </div>
                <div className="form_box">
                    <p>{message.from_user_id !== user.id ? (<span><b>From:</b> {message.from.name}</span>) : (
                        <span><b>To:</b> {message.to.name}</span>)}</p>
                    <p><b>Item:</b> {message.product.name}</p>
                    <p><b>Item ID:</b> {message.product.id}</p>
                    {message.from_user_id == user.id ? (
                        <p
                            style={{marginTop: "40px", marginBottom: "40px", border: "5px", borderColor: "#000"}}
                            dangerouslySetInnerHTML={{__html: message.message}}></p>
                    ) : (
                        <div>
                            <p
                                style={{marginTop: "40px", marginBottom: "40px", border: "5px", borderColor: "#000"}}
                                dangerouslySetInnerHTML={{__html: message.message}}
                            ></p>

                            <p><b>Reply:</b></p>
                            <CKEditor
                                name="description"
                                editor={ClassicEditor}
                                data=""
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    updateMessage(data)
                                }}
                                config={{
                                    removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Indent", "BulletedList", "Table"]
                                }}
                            />
                        </div>
                    )}

                    <div className="input-group">
                        {message.from_user_id == user.id ? (
                            <button className="btn btn-blue"
                                    style={{marginTop: "40px"}}
                                    onClick={() =>
                                        history.push(`/myaccount/my-messages/${message.to_user_id}/${message.product.id}`)
                                    }
                            >Send Another Message</button>
                        ) : (
                            <button className="btn btn-blue"
                                    style={{marginTop: "40px"}}
                                    onClick={() => sendMessage()}
                            >Reply</button>
                        )}

                        <button
                            className="btn btn-blue"
                            style={{marginLeft: "40px", marginTop: "40px"}}
                            onClick={() => {
                                deleteMessage()
                            }}>Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;
