import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router'
const BuyStep1 = (props) => {
    const history = useHistory()
    console.log("BuyStep1", props)
    const creditdata = props.location.data ? props.location.data : {price: '', credits: ''}
    // alert(creditdata[0].price)

    if(!props.location.data) {
        history.replace('BuyCredits')
    }

    const [terms, setTerms] = useState({
        read: false,
      });

    const changeTermsState = (e) => {
        setTerms((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.checked == true ? true : false,
        }));
        console.log("TERMS", terms);
    
      };

      const handlesubmit =()=>{

        if(terms.read){
           history.replace({ pathname: "./BuyStep2", creditdata: creditdata })
        }
      }

    return (
        <section class="buy_credits_outer">
            <div class="container">
                <div class="buy_credit_second_title col-md-12 text-center">
                    <h2>Your Order Summary</h2>
                </div>
                <div class="row px-2 mb-5">
                    <div class="col-md-6  offset-md-3" >
                        <div class="row border  py-2">
                            <div class="col-9 font-weight-bold">Description</div>
                            <div class="col-3 font-weight-bold">Total</div>
                        </div>
                        <div class="row border py-3">
                            <div class="col-9 text_black "><p>{creditdata.credits} Credit (s) </p></div>
                            <div class="col-3 ">${creditdata.price}</div>
                        </div>
                        <div class="row border py-3">
                            <div class="col-9 font-weight-bold">Total Amount</div>
                            <div class="col-3 text_black"><b>${creditdata.price}</b></div>
                        </div>
                        <div class="my-4 d-flex">
                            <input onChange={(e) => changeTermsState(e)} type="checkbox" name="read" class=" mt-2" /><p class="ml-3 ">I have read and understand the<a href="/terms"><span class="text_orange font-weight-bold"> Terms and Conditions</span></a></p>
                        </div>
                        <div class="col-md-12 plr-24 text-center">
                            <button type="submit" class="btn btn-blue w-50 btn-b rounded-0" onClick={handlesubmit} >Pay Now</button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default BuyStep1
