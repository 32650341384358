import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import store from "./Redux/store";

import { Provider, useSelector, useDispatch } from "react-redux";
const Token = localStorage.getItem("token");
//axios.defaults.baseURL = "http://localhost/api/auth/";
//comment out the below url when uploading in a2hosting
axios.defaults.baseURL = "https://justacredit.com/admin/public/api/auth/";
axios.defaults.headers.common["Authorization"] = "Bearer " + Token;
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
