import React, {useEffect, useState} from "react";
import axios from "axios";
import swal from "sweetalert";
import {useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";

const customStyles = {
    header: {
        style: {
            minHeight: "56px",
        },
    },
    headRow: {
        style: {
            backgroundColor: "#e8ebf1",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "#c2c2c2",
            ":first-of-type": {
                borderLeftColor: "#c2c2c2",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
            },
        },
    },
    headCells: {
        style: {
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRightStyle: "solid",
            borderRightWidth: "2px",
            // borderRightColor: "white",
            borderRightColor: "rgb(194, 194, 194)",

            ":first-of-type": {
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
                borderLeftColor: "white",
            },
        },
    },
    cells: {
        style: {
            borderRightStyle: "solid",
            fontSize: "15px",
            borderRightWidth: "1px",
            borderRightColor: "#c2c2c2",
            borderBottomColor: "#c2c2c2",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            ":first-of-type": {
                borderLeftColor: "#c2c2c2",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
            },
        },
    },
};

function MyListedItemsClaimed() {

    const dateSort = (rowA, rowB) => {
        let dateA = new Date(rowA.date);
        let dateB = new Date(rowB.date);
        if (dateA > dateB) {
            return 1;
        }
        if (dateB > dateA) {
            return -1;
        }
        return 0;
    };
    const history = useHistory();
    const [products, getProducts] = useState([]);
    const [response, setResponse] = useState(false);
    const columns = [
        {
            name: "Date Claimed",
            selector: (row) => row.date,
            sortable: true,
            sortFunction: dateSort
        },
        {
            name: "Item ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Item Title",
            selector: (row) => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: "Shipping Info",
            selector: (row) => row.address,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => row.status !== "Received",
                    style: row => ({ color: row.status !== "Received" ? 'red' : 'inerit' }),
                },
            ],
        },
        {
            name: "Actions",
            key: "action",
            className: "action",
            compact: true,
            wrap: true,
            align: "left",
            sortable: false,
            width: "210px",
            cell: (item) => {
                return (
                    <div style={{width: "100%",marginTop: "10px", marginBottom: "5px"}}>
                       
						 {(item.status !== 'Received' || item.status === 'Received' && item.user_id) ? (
						          
                             <div className="col-12" style={{marginBottom: "5px"}} onClick={() => pushToDetail(item.id)}>
                                <button className="btn btn_small_green w-100 btn-blue-hover">
                                    View
                                </button>
                            </div>
                        ) : null}
						
                        {(item.status !== 'Received' && item.user_id) ? (
						          
                            <div
                                className="col-12"
                                style={{marginTop: "5px", marginBottom: "5px"}}
                            >
							    
                                <button className="btn btn_small_green w-100 btn-blue-hover" onClick={() => {
                                    history.push(`/myaccount/my-messages/${item.user_id}/${item.id}`)
                                }}>
                                    Send Message
                                </button>
                            </div>
                        ) : null}
                        {item.status === "Shipped" || item.status === "Received" ? null : (
                            <div className="col-12" style={{marginBottom: "5px"}} onClick={() => shipItem(item.id)}>
                                <button className="btn btn_small_green w-100 btn-blue-hover">
                                    Ship
                                </button>
                            </div>
                        )}
                        {item.status !== "Shipped" ? null : (
                            <div className="col-12" onClick={() => updateShippingInfo(item.id)}>
                                <button className="btn btn_small_green w-100 btn-blue-hover">
                                    Update Shipping Info
                                </button>
                            </div>
                        )}
                    </div>
                );
            },
        }
    ];
    const getData = async () => {
        try {
            const response = await axios.get("myitem_claims");
		
            if (response.data.status === false) {
                setResponse(false);
                console.log("mylisteditem claimed", response.data.massage);
            }
            if (response.data.status === true) {
                setResponse(true);
                console.log("response", response);
                getProducts(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const pushToDetail = (productId) => {
        history.push({pathname: `/item-details/${productId}`});
    };
    const shipItem = (productId) => {
        axios.get(`/myitem_shipping_status/${productId}`).then((data) => {
            console.log("shippping", data);
            if (data.data.status == true) {
                swal({
                    title: "Shipping Status Changed to Shipped",
                    icon: "success",
                    button: "OK",
                }).then(() => getData());
                console.log("product is shipping");
            }
        });
    };
    const updateShippingInfo = (productId) => {
        history.push({pathname: `/myaccount/shipping/${productId}`});
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>My Listed Items Claimed</h2>
                </div>
                <div className="transaction_outer mt-50">
                    {products.length == 0 ? (
                        <div
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                display: "flex",
                            }}
                        >
                            <h4>No items of yours are claimed</h4>
                        </div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={products}
                            highlightOnHover={true}
                            customStyles={customStyles}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default MyListedItemsClaimed;
