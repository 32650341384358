import "./App.css";
import { useEffect } from "react";
import Routes from "./Routes/Routes";
import { Provider, useSelector, useDispatch } from "react-redux";
import store from "./Redux/store";
import { fetchUsers } from './Redux'
import { fetchLocalUser } from './Redux'

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    console.log("app js");
    dispatch(fetchLocalUser())
  }, [])


  return (

    <div>
      <Routes />
    </div>

  );
}

export default App;
