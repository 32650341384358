import React, {useState, useEffect} from 'react'
import axios from 'axios'

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {useDispatch} from "react-redux";
import {fetchLocalUser} from '../../Redux'

import swal from "sweetalert";

const AddItem = (props) => {
    
    const dispatch = useDispatch()
	
    const [deliveryserve, setDelivery] = useState([])
    const getDelivery = async () => {
        try {
            const response = await axios.get('shippings')
			
			console.log('tarek2'+response.data.default_shippings);
            setDelivery(
                response.data.data
            )
            setValues((prevState) => ({
                ...prevState,
                delivery: response.data.default_shippings ?? []
            }));
        } catch (error) {
            console.log(error);
        }
    }
    const [categories, setCategories] = useState([])
    const getCategory = async () => {
		
        try {
            const response = await axios.get('category');
            setCategories(
                response.data.data
            )
        } catch (error) {
            console.log(error);
        }
    }
    const [subCategory, setSubCategory] = useState([])
    const getSubCategory = async (e) => {
        try {
            const data = {category_id: e.target.value}
            const response = await axios.post('subcategory', data)
            setSubCategory(
                response.data.data
            )
        } catch (error) {
            console.log("subcategory", error);
        }
    }
	
	   const [rating, setRating] = useState("");
	    const getCredit = async () => {
        try {
            const res = await axios.get("token");
            console.log("mydashboard", res.data);
            if (res.data.status == true) {
             
                setRating(res.data.response.sip);
				if(res.data.response.sip==null){
			
			document.getElementById("clear_shipping_remember").checked=true;
		
		}else{
			document.getElementById("shipping_remember").checked=true;
		}
            }
            console.log("TOKEN", res);
        } catch (error) {
            console.log("error");
        }
    };
	    useEffect(() => {
        // setUsername(userName)
        getCredit();
    }, []);
	function test(){
		if(rating==3){
			
			document.getElementById("clear_shipping_remember").checked=true;
		
		}
	}
    useEffect(() => {
        getCategory()
        getDelivery()
		test()
    }, []);
	


    const [values, setValues] = useState({
        delivery: [],
        category: "",
        subCategory: "",
        title: "",
        description: "",
        file1: "",
        file2: "",
        file3: "",
        file4: "",
        file5: "",
        file6: "",
        shipping_remember: rating,
        clear_shipping_remember: false

    })
    const [reset, setReset] = useState('')
	
    const emptyInputFiles = (file) => {
        var x = document.getElementsByName(file);
        if (x.length) {
            x[0].value = "";
        }
        setValues(prevState => ({
            ...prevState,
            [file]: ''
        }))
		if(file=='file1'){
				document.getElementById("img1").src = '';
			}else if(file=='file2'){
				document.getElementById("img2").src = '';
			}else if(file=='file3'){
				document.getElementById("img3").src = '';
			}else if(file=='file4'){
				document.getElementById("img4").src = '';
			}else if(file=='file5'){
				document.getElementById("img5").src = '';
			}else if(file=='file6'){
				document.getElementById("img6").src = '';
			}
    };
    const updateDescription = (description) => {
        setValues((prevState) => ({
            ...prevState,
            description: description
        }));
    }
    const onChangeValue = (e) => {

        if (e.target.type == "file") {
            
			
			//alert(e.target.value);
			var fileName1 = e.target.value;
			var idxDot = fileName1.lastIndexOf(".") + 1;
			var extFile = fileName1.substr(idxDot, fileName1.length).toLowerCase();
        if (extFile=="jpg" || extFile=="bmp" || extFile=="png"){
            setValues({
                ...values,
                [e.target.name]: e.target.files[0],
            });
			
			if(e.target.name=='file1'){
				document.getElementById("img1").src = URL.createObjectURL(e.target.files[0]);
			}else if(e.target.name=='file2'){
				document.getElementById("img2").src = URL.createObjectURL(e.target.files[0]);
			}else if(e.target.name=='file3'){
				document.getElementById("img3").src = URL.createObjectURL(e.target.files[0]);
			}else if(e.target.name=='file4'){
				document.getElementById("img4").src = URL.createObjectURL(e.target.files[0]);
			}else if(e.target.name=='file5'){
				document.getElementById("img5").src = URL.createObjectURL(e.target.files[0]);
			}else if(e.target.name=='file6'){
				document.getElementById("img6").src = URL.createObjectURL(e.target.files[0]);
			}
			
			
			
        }else{
            swal({
                    title: "Image files can only be .jpg, .bmp or .png",
                    icon: "error",
                    button: " OK",
                })
			e.target.value='';
        } 
			
        }

		else if (e.target.name == 'delivery') {

            if (values.delivery.includes(+e.target.value)) {
                console.log("VALUES:", values.shipping_remember)
                setValues(prevState => ({
                    ...prevState,
                    delivery: prevState.delivery.filter(item => item != e.target.value)
                }))
            } else {

                var oldValues = JSON.parse(JSON.stringify(values.delivery))
                setValues(prevState => ({
                    ...prevState,
                    delivery: [...prevState.delivery, +e.target.value]
                }))
            }
        }else {

            setValues({
                ...values,
                [e.target.name]: e.target.value
            })

		
        }
    }
	

    const addProduct = async () => {

        try {

            if (!values.description == "" && !values.title == "" && !values.file1 == "" && !values.subCategory == "" && values.delivery.length>1) {
                const formData = new FormData();
                formData.append(
                    "category_id", values.category
                );
                formData.append(
                    "subcategory_id", values.subCategory
                );
                formData.append(
                    "shipping_remember", values.shipping_remember || rating
                );
                
                formData.append(
                    "name", values.title
                );
                formData.append(
                    "description", values.description
                );
                formData.append(
                    "primary_image", values.file1
                );

                values.delivery.forEach((item, index) => {
                    formData.append(
                        `shipping_id[${index}]`, item
                    );

                })

                // NEW ADDED:-
                // START
                var imagesArrayExcludingPrimary = []
                for (var i = 2; i < 7; i++) {
                    if (values[`file${i}`]) {
                        imagesArrayExcludingPrimary.push(values[`file${i}`])
                    }
                }


                for (var i = 0; i < imagesArrayExcludingPrimary.length; i++) {
                    formData.append('image[]', imagesArrayExcludingPrimary[i]);
                }
                // END

                const config = {headers: {'Content-Type': 'multipart/form-data'}};
                const res = await axios.post('add_product', formData, config)
                if (res.data.status) {
                    dispatch(fetchLocalUser())
                    swal({
                        title: "Item Added Successfully",
                        icon: "success",
                        button: " OK",
                    }).then(() => window.location.href = '/myaccount/my-list')
                    // history.push('/myaccount/my-list')

                    // window.location.reload()

                }

            } else {
				
					if(values.delivery.length <2){
					 swal({
                    title: "Please select at least one shipping method",
                    icon: "error",
                    button: "OK",
                });
				}else{
					swal({
                    title: "Please input all fields",
                    icon: "error",
                    button: "OK",
                });
				}
				
                
            }
        } catch (error) {
            console.log(error);
            swal({
                title: "Error",
                icon: "error",
                button: " OK",
            })
        }
    }
	

    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>Add Item</h2>
                </div>
                <div className="form_box">
                    <div className="row mlr-24">
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <select className="form-control form_input" name="category" onChange={(e) => {
                                    getSubCategory(e);
                                    onChangeValue(e);
                                }}>
                                    <option value="" disabled selected>Select Categories</option>
                                    {categories.map((item, index) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <select className="form-control form_input" name="subCategory" onChange={(e) => {
                                    onChangeValue(e)
                                }}>
                                    <option disabled selected>Select Subcategories</option>
                                    {subCategory.map((item, index) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12 plr-24">
                            <div className="form_group">
                                <input type="text" className="form-control form_input" placeholder="Enter Title"
                                       name="title" onChange={onChangeValue}/>
                                {/* <h5 style={{ color: "red" }}>{error.title}</h5> */}
                            </div>
                        </div>
                        <div className="col-md-12 plr-24">
                            <div className="form-group">
                                {/* <textarea className="form-control form_input" cols="8" rows="8" placeholder="Enter Desciption" name="description" onChange={onChangeValue}></textarea> */}

                                <CKEditor
                                    name="description"
                                    editor={ClassicEditor}
                                    data=""
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        updateDescription(data)
                                    }}
                                    config={{
                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Indent", "Table"]
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                1.
								
                                <input type='file' accept='image/jpg, image/bmp, image/png' onChange={(e) => onChangeValue(e)} name="file1"/>
                                <img  class="img-thumbnail1" id='img1'   />
								{
                                    values.file1 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file1')}></i>
                                }
                                <br/>
                                <small style={{color: "#856404"}} className="">* Primary Image</small>
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                4.
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file4" onChange={(e) => onChangeValue(e)}
                                       name="file4"/>
									   <img class="img-thumbnail1" id='img4'   />
                                {
                                    values.file4 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file4')}></i>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                2.
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file2" onChange={(e) => onChangeValue(e)}
                                       name="file2"/>
									   <img class="img-thumbnail1"  id='img2'   />
                                {
                                    values.file2 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file2')}></i>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                5.
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file5" onChange={(e) => onChangeValue(e)}
                                       name="file5"/>
									   <img class="img-thumbnail1" id='img5'   />
                                {
                                    values.file5 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file5')}></i>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                3.
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file3" onChange={(e) => onChangeValue(e)}
                                       name="file3"/>
									   <img class="img-thumbnail1" id='img3'   />
                                {
                                    values.file3 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file3')}></i>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                6.
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file6" onChange={(e) => onChangeValue(e)}
                                       name="file6"/>
									   <img class="img-thumbnail1" id='img6'   />
                                {
                                    values.file6 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file6')}></i>
                                }
                            </div>
                        </div>
                        <div className="col-md-12 plr-24">
                            <div className="form-group mt-5">
                                <label><p className="lead font-weight-bold">Shipping Method</p></label><br/>

                                {deliveryserve.map((item, index) => {

                                    return (
                                        <>
                                            <input type='checkbox' className="ml-3" id={item.name} value={item.id}
                                                   onChange={(e) => onChangeValue(e)} name="delivery"
                                                   checked={values.delivery.includes(item.id)}/>
                                            <label for={item.name} value={item.id} className="ml-2">{item.name}</label>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-md-5 plr-24">
                            <button type="submit" className="btn btn-blue btn-blue-big" onClick={addProduct}>Add Item
                            </button>
                        </div>
						                        <div class="col-md-6">
                            <div>
                        <input   type='radio' className="ml-3" id="shipping_remember" name="shipping_remember" value="1" onChange={(e) => onChangeValue(e)}/>
                                <label htmlFor="shipping_remember" value="true" className="ml-2">Save shipping method
                                    for future items</label>
                            </div>
                            <div>
						
<input   type='radio' className="ml-3" id="clear_shipping_remember"
                                       name="shipping_remember"
                                       value="2" onChange={(e) => onChangeValue(e)} />
                                <label htmlFor="clear_shipping_remember" value="false" className="ml-2">Clear shipping
                                    method for future items</label>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div> 
    )
}

export default AddItem
