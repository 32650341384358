import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from 'sweetalert'
import { Link, useHistory } from 'react-router-dom'
const Item = (props) => {
  console.log("props are: ", props);
  // const history = useHistory()
  // const [disabled, setDisabled] = useState(false)
  // const [credit, setCredit] = useState("")
  // const getCredit = async () => {
  //   try {
  //     const res = await axios.get('token')
  //     if (res.data.status == true) {
  //       // setCredit(res.data.response.credits)
  //     }
  //     console.log("TOKEN", res);
  //   } catch (error) {
  //     console.log("error");
  //   }
  // }

  var newArr = JSON.parse(JSON.stringify(props.products));
  var newProductArray = [];
  while (newArr.length) {
    newProductArray.push(newArr.splice(0, 5));
  }

  console.log("Product Slices is: ", newProductArray);
  // const ClaimProduct = async (product) => {
  //   console.log("id", product);
  //   setDisabled(true)
  //   const data = {
  //     product_id: product.id
  //   }
  //   try {
  //     const res = await axios.post('product_claims', data)
  //     console.log("claim product", res);
  //     setDisabled(false)
  //     swal({
  //       title: `Product claimed`,
  //       icon: "success",
  //      button: "OK",
  //     })


  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // }
  // useEffect(() => {
  //   // getCredit()
  // })
return (
  <>
    <style>
      {`
        @media (min-width: 1200px) {
          .row-cols-xl-5 > * {
            flex: 0 0 20%;
            max-width: 20%;
          }
        }
      `}
    </style>
    {newProductArray.map((arr, index) => (
      <div className="row row-cols-xl-5" key={`Root${index}`}> {/* Added row-cols-xl-5 class */}
        {arr.map((product) => (
          <div
            key={product.id}
            className="col-lg-3 col-md-4 mb-5"
            style={{ cursor: "pointer" }}
          >
            <div className="item">
              <div className="latest_item">
                <div className="latest_item_img" onClick={() => props.pushToDetail(product.id)}>
                  <img src={product.primary_image} alt="" />
                </div>
                <h3>{product.name}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    ))}
  </>
);

};

export default Item;
