import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import axios from 'axios'
function TopItems(props) {
    const [products, setProducts] = useState([]);
    const history = useHistory()
    const pushToDetail = (productId) => {
        history.push({ pathname: `/item-details/${productId}` });
    };
    useEffect(() => {
        //  console.log("current item user", user);
        axios
            .get("get_product_most_vie2")
            .then((res) => {
                if (res.data.status == true && res.data.data.data?.length) {
                    console.log("Products are: ", res.data.data);
                    setProducts(res.data.data.data.slice(0, 2))

                    console.log("New Product Array: ", products);
                }
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <div className="top_item_box mx-xl-0">
            <h2>Hot Items</h2>
            {
                products.map((item) => {
                    return (


                        <div className="top_item">
                            <a href="#" onClick={() => pushToDetail(item.id)}>
                                <div className="top_item_img"  >
                                    <img src={item.primary_image} alt="" />
                                </div>
                                
								<h3 style={{ fontSize: "14px" }}>{item.name}</h3>
                            </a>
                        </div>

                    )
                })
            }
            {/* <div className="top_item">
                <a href="#">
                    <div className="top_item_img">
                        <img src="/images/top_item_img_02.png" alt="" />
                    </div>
                    <h3>Praesentium Ptatum Deleniti</h3>
                </a>
            </div> */}
        </div>
    )
}

export default TopItems
