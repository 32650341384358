import React, {useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import swal from "sweetalert";
import GoogleAd from "../Ads/GoogleAd";
import AdSense from "react-adsense";


const useStyles = makeStyles((theme) => ({
    errmsg: {
        margin: '20px',
        color: "red",
        fontSize: '14px',
        lineHeight: '0px',
    },
}));


const ContactUs = () => {
    const classes = useStyles();
    const [values, setValues] = useState({
        fname: '',
        lname: '',
        email: '',
        subject: '',
        message: '',
    })

    const [error, setError] = useState({
        fname: '',
        lname: '',
        email: '',
        subject: '',
        message: '',
    })

    const Newerror = () => {
        setError({fname: ""})
        setError({lname: ""})
        setError({email: ""})
        setError({subject: ""})
        setError({message: ""})

    }

    const contactus = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
        console.log('name', e.target.name, 'value', e.target.value)
    }


    const handlesubmit = async () => {


        Newerror()
        var response = await axios.post(`contact`, values);
        console.log('response', response)

        if (response.data.message.fname) {
            setError({fname: "Please enter your first name."})
        } else if (response.data.message.lname) {
            setError({lname: "Please enter your last name."})
        } else if (response.data.message.email) {
            setError({email: "Please enter your email."})
        } else if (response.data.message.subject) {
            setError({subject: "Please enter your subject."})
        } else if (response.data.message.message) {
            setError({message: "Please enter your message."})
        } else if (response.data.status == "true") {
            swal({
                title: "Your message has been sent",
                icon: "success",
                button: "OK",
            })
            setValues({
                fname: '',
                lname: '',
                email: '',
                subject: '',
                message: '',
            })
        }


        // else {
        // 	swal({
        // 		title: "Something Went wrong",
        // 		icon: "error",
        // 		button: "OK",
        // 	  });
        // }


    }


    return (<div>
            <section className="buy_credits_outer">
                <div className="container">
                    <div class="row">
                        <div className="col-3 mx-auto">
                            <AdSense.Google
                                client='ca-pub-1905261535699460'
                                slot='5648814287'
                                style={{ width: 300, height: 600, float: 'left' }}
                                format=''
                            />
                        </div>
                        <div class="col-6">
                            <div className="row mb-4">
                                <div className="login_sub_title col-md-12 text-center">
                                    <h2>Contact Us</h2>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="form_box pr-xl-5 ">
                                    <div className="row mlr-24 ">

                                        <div className="col-md-6 plr-24">
                                            <div className="form-group">
                                                <input type="text" className="form-control form_input"
                                                       placeholder="Enter First Name" name="fname"
                                                       value={values.fname}
                                                       onChange={contactus}/>
                                            </div>
                                            {error.fname ? (
                                                <p className={classes.errmsg}>
                                                    {error.fname}
                                                </p>
                                            ) : null}
                                        </div>


                                        <div className="col-md-6 plr-24">
                                            <div className="form-group">
                                                <input type="text" className="form-control form_input"
                                                       placeholder="Enter Last Name" value={values.lname}
                                                       name="lname"
                                                       onChange={contactus}/>
                                            </div>
                                            {error.lname ? (
                                                <p className={classes.errmsg}>
                                                    {error.lname}
                                                </p>
                                            ) : null}
                                        </div>

                                        <div className="col-md-12 plr-24">
                                            <div className="form-group">
                                                <input type="text" className="form-control form_input"
                                                       placeholder="Enter Email Address" value={values.email}
                                                       name="email"
                                                       onChange={contactus}/>
                                            </div>
                                            {error.email ? (
                                                <p className={classes.errmsg}>
                                                    {error.email}
                                                </p>
                                            ) : null}
                                        </div>

                                        <div className="col-md-12 plr-24">
                                            <div className="form-group">
                                                <input type="text" className="form-control form_input"
                                                       placeholder="Enter Subject" value={values.subject}
                                                       name="subject"
                                                       onChange={contactus}/>
                                            </div>
                                            {error.subject ? (
                                                <p className={classes.errmsg}>
                                                    {error.subject}
                                                </p>
                                            ) : null}
                                        </div>

                                        <div className="col-md-12 plr-24">
                                            <div className="form-group">
                                            <textarea className="form-control form_input"
                                                      placeholder="Write Your Message" value={values.message}
                                                      name="message" onChange={contactus} rows="4" cols="50"></textarea>
                                                {/* <input type="text" className="form-control form_input" placeholder="Write Your Message" value={values.message} name="message" onChange={contactus} /> */}
                                            </div>
                                            {error.message ? (
                                                <p className={classes.errmsg}>
                                                    {error.message}
                                                </p>
                                            ) : null}
                                        </div>

                                        <div className="col-md-6 plr-24 mb-4">
                                            <button className="btn btn-blue w-50 " onClick={handlesubmit}>Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 mx-auto">
                            <AdSense.Google
                                client='ca-pub-1905261535699460'
                                slot='4946144692'
                                style={{ width: 300, height: 600, float: 'left' }}
                                format=''
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ContactUs
