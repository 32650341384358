//import { useDispatch, useSelector } from 'react-redux'
//import { isEmpty } from './Checks'
//import { useHistory } from "react-router-dom";
//import Logout from './Logout';
import React from 'react'
import HeaderTop from './HeaderTop';
import HeaderCenter from './HeaderCenter';
import HeaderBottom from './HeaderBottom';
export default function Header(props) {
    //var data = useSelector(state => state.data)
    //   var key = Object.keys(data)
    // var loggedIn = useSelector(state => state.loggedIn)
    //console.log('state data ', data, 'state loggedin', loggedIn)
    // var values =Object.values(data)


    // let history = useHistory();

    // let dispatch = useDispatch();

    // const logout = () => {
    //     console.log('logout Clicked')
    //     dispatch({ type: 'Remove_Account' });
    //     console.log('new Data : ', data, 'loggedIn is : ', loggedIn)
    //     history.push('/')
    // }

    return (
        <>
            <HeaderTop />
            <HeaderCenter />
            <HeaderBottom />
        </>
    )

}