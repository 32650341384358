import { FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE, FETCH_USER_REMOVE, FETCH_TOTAL_PRODUCT_SUCCESS } from './userTypes';

const initialState = {
    user: {},
    isLoggedIn: false,
    error: '',
    token: '',
    totalProducts: 0

}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_SUCCESS: return {
            ...state,
            isLoggedIn: true,
            user: action.payload,
            token: action.payload.token,
            error: '',
        }
        case FETCH_USER_REMOVE: return {
            ...state,
            isLoggedIn: false,
            user: {},
            token: localStorage.clear(),
            error: ''
        }
        case FETCH_USERS_FAILURE: return {
            ...state,
            isLoggedIn: false,
            user: {},
            token: '',
            error: action.payload
        }
        case FETCH_TOTAL_PRODUCT_SUCCESS: return {
            ...state,
            totalProducts: action.payload.totalProducts
        }
        default: return state
    }
}

export default UserReducer;