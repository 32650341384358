import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {fetchLocalUser} from "../../Redux";
import swal from "sweetalert";
import {useHistory} from "react-router-dom";

const SendMessage = (props) => {

    const dispatch = useDispatch()
    const history = useHistory();
    const [product, setProduct] = useState({
        id: null,
        delivery: [],
        category: "",
        subCategory: "",
        title: "",
        description: "",
        username: "",
        user_id: ""
    });
    const [user, setUser] = useState({
        id: null,
    });
    const [message, setMessage] = useState({
        id: null,
        message: ""
    });

    useEffect(async () => {
        await mounted();
    }, []);

    const mounted = async () => {
        getProductDetails();
        getUserDetails();
    };

    const getUserDetails = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(
                    `user/${props.match.params.uid}`
                );
                setUser({
                    id: response.data.id,
                    name: response.data.name,
                });
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    }

    const getProductDetails = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(
                    `product_details/${props.match.params.pid}`
                );

                let oldProduct = JSON.parse(JSON.stringify(response.data.data));
                delete oldProduct["primary_image"];

                setProduct({
                    id: oldProduct.id,
                    delivery: response.data.shipping?.map((item) => item.id),
                    category: oldProduct.category_id,
                    subCategory: oldProduct.subcategory_id,
                    title: oldProduct.name,
                    description: oldProduct.description,
                    username: oldProduct.username,
                    user_id: oldProduct.user_id,
                });

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Send message to owner of product.
     * @returns {Promise<void>}
     */
    const sendMessage = async () => {
        try {

            const res = await axios.post('message/send', {
                "to": user.id,
                "body": message.message,
                "product": product.id
            })

            if (res.status) {
                dispatch(fetchLocalUser())
                swal({
                    title: "Message sent successfully",
                    icon: "success",
                    button: " OK",
                }).then(() => {
                    history.push(`/myaccount/my-messages`)
                })
            }
        } catch (error) {
            console.log(error.data);
            swal({
                title: "Error",
                icon: "error",
                button: " OK",
            })
        }
    }

    /**
     *
     * @param message
     */
    const updateMessage = (message) => {
        setMessage((prevState) => ({
            ...prevState,
            message: message
        }));
    }

    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>Send Message</h2>
                </div>
                <div className="form_box">
                    <p><strong>To:</strong> {user.name}</p>
                    <p><strong>Item:</strong> {product.title}</p>
                    <p><strong>Item ID:</strong> {product.id}</p>
                    <div
                        style={{marginTop: "40px"}}>
                        <CKEditor
                            name="description"
                            editor={ClassicEditor}
                            data=""
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                updateMessage(data)
                            }}
                            config={{
                                removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Indent", "BulletedList", "Table"]
                            }}
                        />
                    </div>
                </div>
                <div className="panel-footer">
                    <div className="input-group">
                        <button type="submit"
                                className="btn btn-blue btn-blue-big"
                                style={{marginTop: "40px"}}
                                onClick={sendMessage}>Send
                            Message
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendMessage;
