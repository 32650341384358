import React from 'react';

export default class GoogleAd extends React.Component {
    componentDidMount () {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    render () {
        return (
            <ins className='adsbygoogle'
                 style={{display: "inline-block",width:"700px"}}
                 data-ad-client={this.props.client}
                 data-ad-slot={this.props.slot}
                 data-ad-format='auto'
                 data-full-width-responsive='true'
            />
        );
    }
}