import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Link, useRouteMatch, useHistory } from "react-router-dom"

function BuyCredits(props) {
    let history = useHistory();
    const [datas, setData] = useState([])
    const fetchData = async () => {
        try {
            const res = await axios.get('credit')
            if (res.status === 200) {
                setData(res.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchData()
        console.log(datas);
    }, [])

    return (
        <div>
            <section className="buy_credits_outer">
                <div className="container">
                    <div className="buy_credits_list">
                        <div className="row mlr-71">
                            {datas.map((item, index) => {

                                return (
                                    <div className="col-md-6 col-lg-4 plr-71">
                                        <div className="buy_credits_item">
                                            <div className="buy_credits_top">
                                                <div className="buy_credits_img">
                                                    <img src={item.image} alt="image not found" />
                                                </div>
                                                <h3>{item.title} <span>${item.price}</span></h3>
                                                <p>{item.detail}</p>
                                            </div>
                                            <div className="buy_credits_btn" onClick={() => history.push({ pathname: "/BuyStep1", data: item })} >
                                                <a className="btn btn-blue">Buy</a>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}

                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default BuyCredits
