import React, {useState, useEffect} from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {BrowserRouter as Router, Route, Link, Switch, useParams, useRouteMatch} from "react-router-dom";
import {Provider, useSelector, useDispatch} from "react-redux";
import {fetchLocalUser} from "../../Redux";
import swal from "sweetalert";

const AddItem = (props) => {

    console.log("Props are: ", props);

    let history = useHistory();
    let {path, url} = useRouteMatch();

    useEffect(() => {
        console.log("Current Product is: ", product);
        // console.log("Current image is: ", values);
    });
    useEffect(async () => {
        mounted();
        console.log("urlfromadditem", {
            path,
            url,
        });
    }, []);

    const dispatch = useDispatch();
    const [deliveryserve, setDelivery] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [product, setProduct] = useState({
        id: null,
        delivery: [],
        category: "",
        subCategory: "",
        title: "",
        description: "",
    });
    const [oldImagesArray, setOldImagesArray] = useState([]);
    const [oldPrimaryImage, setOldPrimaryImage] = useState([]);
		   const [rating, setRating] = useState("");
	    const getCredit = async () => {
        try {
            const res = await axios.get("token");
            console.log("mydashboard", res.data);
            if (res.data.status == true) {
             
                setRating(res.data.response.sip);
				if(res.data.response.sip==null){
			
			document.getElementById("clear_shipping_remember").checked=true;
		
		}else{
			document.getElementById("shipping_remember").checked=true;
		}
            }
            console.log("TOKEN", res);
        } catch (error) {
            console.log("error");
        }
    };
	    useEffect(() => {
        // setUsername(userName)
        getCredit();
    }, []);
	function test(){
		if(rating==3){
			
			document.getElementById("clear_shipping_remember").checked=true;
		
		}
	}
    useEffect(() => {

		test()
    }, []);
    const [values, setValues] = useState({
            file1: "",
            file2: "",
			file3: "",
			file4: "",
			file5: "",
			file6: "",
    
        shipping_remember: rating,
        clear_shipping_remember: false
    });

    const getDelivery = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get("shippings");
                console.log("deliver", response.data.data);
                setDelivery(response.data.data);
                resolve(response);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    const getCategory = () => {
        return new Promise(async (resolve, reject) => {
            try {
                var response = await axios.get("category");
                console.log("category", response);
                setCategories(response.data.data);
                resolve(response);

                console.log("cat seletced", values.category);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    };

    const getSubCategory = (e, match) => {
        console.log("category to find Is: ", e);
        return new Promise(async (resolve, reject) => {
            try {
                const data = {category_id: e?.target?.value ? e.target.value : e};

                const response = await axios.post("subcategory", data);
                console.log("subcategory : ", response.data.data);
                setSubCategory(response.data.data);

                if (!response.data.data?.find(item => +item.id == (match ? +match : +product.subCategory))) {
                    console.log('It doesnt')
                    setProduct(prevState => ({
                        ...prevState,
                        subCategory: response.data.data.length ? response.data.data[0].id : null

                    }))
                } else {
                    console.log("It Does")
                }

                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    };

    const getProductDetails = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(
                    `product_detailss/${props.match.params.id}`
                );
				
				console.log('test started', response.data.status);
				
				if(response.data.status==2){
						 swal({
                        title: "You are not allowed to view this item",
                        icon: "error",
                        button: "OK",
                    });
					history.push("/myaccount/my-list");
				
				}else{
					                let oldProduct = JSON.parse(JSON.stringify(response.data.data));
                delete oldProduct["primary_image"];
                console.log("delete key from product: ", oldProduct);
                console.log(
                    "shipped mapped: ",
                    response.data.shipping.map((item) => item.id)
                );

                setProduct({
                    id: oldProduct.id,
                    delivery: response.data.shipping?.map((item) => item.id),
                    category: oldProduct.category_id,
                    subCategory: oldProduct.subcategory_id,
                    title: oldProduct.name,
                    description: oldProduct.description,
                });

                setOldPrimaryImage(response.data.data.primary_image);
                setOldImagesArray(response.data.image);
				console.log("OldImage Images are: ", response.data.image);
                
				if(response.data.data.primary_image!=null){
				document.getElementById("img1").src = response.data.data.primary_image;}
			    if(response.data.image.length==0){
					document.getElementById("hrf3").style.display = "none";
				 document.getElementById("hrf4").style.display = "none";
				 document.getElementById("hrf5").style.display = "none";
				 document.getElementById("hrf2").style.display = "none";
				 document.getElementById("hrf6").style.display = "none";
				}
	            if(response.data.image.length==1){
                let text5 = response.data.image[0]['image'];
				const myArray5 = text5.split("/");
				document.getElementById("hide2").value = myArray5[7];
				document.getElementById("id2").value = response.data.image[0]['id'];
				if(response.data.image[0]['primary']!=5){
				
					document.getElementById("img2").src = response.data.image[0]['image'];
				}else{
					document.getElementById("hrf2").style.display = "none";
				}
			     document.getElementById("hrf3").style.display = "none";
				 document.getElementById("hrf4").style.display = "none";
				 document.getElementById("hrf5").style.display = "none";
				 document.getElementById("hrf6").style.display = "none";
				}
				if(response.data.image.length==2){
                let text5 = response.data.image[0]['image'];
				const myArray5 = text5.split("/");
				document.getElementById("hide2").value = myArray5[7];
				document.getElementById("id2").value = response.data.image[0]['id'];
				if(response.data.image[0]['primary']!=5){
				
					document.getElementById("img2").src = response.data.image[0]['image'];
				}else{
					document.getElementById("hrf2").style.display = "none";
				}
				let text4 = response.data.image[1]['image'];
				const myArray = text4.split("/");
				document.getElementById("hide3").value = myArray[7];
				document.getElementById("id3").value = response.data.image[1]['id'];
				if(response.data.image[1]['primary']!=5){
				
				document.getElementById("img3").src = response.data.image[1]['image'];
				}else{
					document.getElementById("hrf3").style.display = "none";
				}
				
				
				 document.getElementById("hrf4").style.display = "none";
				 document.getElementById("hrf5").style.display = "none";
				 document.getElementById("hrf6").style.display = "none";
		
				}
				if(response.data.image.length==3){
                let text5 = response.data.image[0]['image'];
				const myArray5 = text5.split("/");
				document.getElementById("hide2").value = myArray5[7];
				document.getElementById("id2").value = response.data.image[0]['id'];
				if(response.data.image[0]['primary']!=5){
				
					document.getElementById("img2").src = response.data.image[0]['image'];
				}else{
					document.getElementById("hrf2").style.display = "none";
				}
				let text4 = response.data.image[1]['image'];
				const myArray = text4.split("/");
				document.getElementById("hide3").value = myArray[7];
				document.getElementById("id3").value = response.data.image[1]['id'];
				if(response.data.image[1]['primary']!=5){
				
				document.getElementById("img3").src = response.data.image[1]['image'];
				}else{
					document.getElementById("hrf3").style.display = "none";
				}
				let text6 = response.data.image[2]['image'];
				const myArray6 = text6.split("/");
				document.getElementById("hide4").value = myArray6[7];
				document.getElementById("id4").value = response.data.image[2]['id'];
				if(response.data.image[2]['primary']!=5){
				
				document.getElementById("img4").src = response.data.image[2]['image'];
				}else{
					document.getElementById("hrf4").style.display = "none";
				}
				
		
				 document.getElementById("hrf5").style.display = "none";
				 document.getElementById("hrf6").style.display = "none";
		
				}
				if(response.data.image.length==4){
                let text5 = response.data.image[0]['image'];
				const myArray5 = text5.split("/");
				document.getElementById("hide2").value = myArray5[7];
				document.getElementById("id2").value = response.data.image[0]['id'];
				if(response.data.image[0]['primary']!=5){
				
					document.getElementById("img2").src = response.data.image[0]['image'];
				}else{
					document.getElementById("hrf2").style.display = "none";
				}
				let text4 = response.data.image[1]['image'];
				const myArray = text4.split("/");
				document.getElementById("hide3").value = myArray[7];
				document.getElementById("id3").value = response.data.image[1]['id'];
				if(response.data.image[1]['primary']!=5){
				
				document.getElementById("img3").src = response.data.image[1]['image'];
				}else{
					document.getElementById("hrf3").style.display = "none";
				}
				let text6 = response.data.image[2]['image'];
				const myArray6 = text6.split("/");
				document.getElementById("hide4").value = myArray6[7];
				document.getElementById("id4").value = response.data.image[2]['id'];
				if(response.data.image[2]['primary']!=5){
				
				document.getElementById("img4").src = response.data.image[2]['image'];
				}else{
					document.getElementById("hrf4").style.display = "none";
				}
			    let text7 = response.data.image[3]['image'];
				const myArray7 = text7.split("/");
				document.getElementById("hide5").value = myArray7[7];
				document.getElementById("id5").value = response.data.image[3]['id'];
				if(response.data.image[3]['primary']!=5){
				
				document.getElementById("img5").src = response.data.image[3]['image'];
				 }else{
					document.getElementById("hrf5").style.display = "none";
				}

			
				 document.getElementById("hrf6").style.display = "none";

				}
				if(response.data.image.length==5){
                let text5 = response.data.image[0]['image'];
				const myArray5 = text5.split("/");
				document.getElementById("hide2").value = myArray5[7];
				document.getElementById("id2").value = response.data.image[0]['id'];
				if(response.data.image[0]['primary']!=5){
				
					document.getElementById("img2").src = response.data.image[0]['image'];
				}else{
					document.getElementById("hrf2").style.display = "none";
				}
				let text4 = response.data.image[1]['image'];
				const myArray = text4.split("/");
				document.getElementById("hide3").value = myArray[7];
				document.getElementById("id3").value = response.data.image[1]['id'];
				if(response.data.image[1]['primary']!=5){
				
				document.getElementById("img3").src = response.data.image[1]['image'];
				}else{
					document.getElementById("hrf3").style.display = "none";
				}
				let text6 = response.data.image[2]['image'];
				const myArray6 = text6.split("/");
				document.getElementById("hide4").value = myArray6[7];
				document.getElementById("id4").value = response.data.image[2]['id'];
				if(response.data.image[2]['primary']!=5){
			
				document.getElementById("img4").src = response.data.image[2]['image'];
				}else{
					document.getElementById("hrf4").style.display = "none";
				}
			    let text7 = response.data.image[3]['image'];
				const myArray7 = text7.split("/");
				document.getElementById("hide5").value = myArray7[7];
				document.getElementById("id5").value = response.data.image[3]['id'];
				if(response.data.image[3]['primary']!=5){
		
				document.getElementById("img5").src = response.data.image[3]['image'];
				 }else{
					document.getElementById("hrf5").style.display = "none";
				}
				let text8 = response.data.image[4]['image'];
				const myArray8 = text8.split("/");
				document.getElementById("hide6").value = myArray8[7];
				document.getElementById("id6").value = response.data.image[4]['id'];
				if(response.data.image[4]['primary']!=5){
				
				document.getElementById("img6").src = response.data.image[4]['image'];
				}else{
					document.getElementById("hrf6").style.display = "none";
				}
				
				
				
				}
				
				if(response.data.image.length>5){
                let text5 = response.data.image[0]['image'];
				const myArray5 = text5.split("/");
				document.getElementById("hide2").value = myArray5[7];
				document.getElementById("id2").value = response.data.image[0]['id'];
				if(response.data.image[0]['primary']!=5){
				
					document.getElementById("img2").src = response.data.image[0]['image'];
				}else{
					document.getElementById("hrf2").style.display = "none";
				}
				let text4 = response.data.image[1]['image'];
				const myArray = text4.split("/");
				document.getElementById("hide3").value = myArray[7];
				document.getElementById("id3").value = response.data.image[1]['id'];
				if(response.data.image[1]['primary']!=5){
				
				document.getElementById("img3").src = response.data.image[1]['image'];
				}else{
					document.getElementById("hrf3").style.display = "none";
				}
				let text6 = response.data.image[2]['image'];
				const myArray6 = text6.split("/");
				document.getElementById("hide4").value = myArray6[7];
				document.getElementById("id4").value = response.data.image[2]['id'];
				if(response.data.image[2]['primary']!=5){
				
				document.getElementById("img4").src = response.data.image[2]['image'];
				}else{
					document.getElementById("hrf4").style.display = "none";
				}
			    let text7 = response.data.image[3]['image'];
				const myArray7 = text7.split("/");
				document.getElementById("hide5").value = myArray7[7];
				document.getElementById("id5").value = response.data.image[3]['id'];
				if(response.data.image[3]['primary']!=5){
				
				document.getElementById("img5").src = response.data.image[3]['image'];
				 }else{
					document.getElementById("hrf5").style.display = "none";
				}
				let text8 = response.data.image[4]['image'];
				const myArray8 = text8.split("/");
				document.getElementById("hide6").value = myArray8[7];
				document.getElementById("id6").value = response.data.image[4]['id'];
				if(response.data.image[4]['primary']!=5){
				
				document.getElementById("img6").src = response.data.image[4]['image'];
				}else{
					document.getElementById("hrf6").style.display = "none";
				}
				
				}
			

                const shipping_id = deliveryserve.find(
                    (item) => item.id == oldProduct.delivery
                );
                if (shipping_id) {
                    setProduct((prevState) => ({
                        ...prevState,
                        delivery: shipping_id.id,
                    }));
                }

                getSubCategory(oldProduct.category_id, oldProduct.subcategory_id);

                resolve(response);
				}

            } catch (error) {
                reject(error);
            }
        });
    };

    const mounted = async () => {
        await getCategory();
        await getDelivery();
        await getProductDetails();
    };

    const renewFiles = () => {
        setValues({
            file1: "",
            file2: "",
			file3: "",
			file4: "",
			file5: "",
			file6: "",
         
        shipping_remember: rating,
        clear_shipping_remember: false
        });
    };

    const emptyInputFiles = (file) => {
        var x = document.getElementsByName(file);
        if (x.length) {
            x[0].value = "";
        }
        setValues(prevState => ({
            ...prevState,
            [file]: ''
        }))
		if(file=='file1'){
				document.getElementById("img1").src = '';
			}else if(file=='file2'){
				document.getElementById("img2").src = '';
			}else if(file=='file3'){
				document.getElementById("img3").src = '';
			}else if(file=='file4'){
				document.getElementById("img4").src = '';
			}else if(file=='file5'){
				document.getElementById("img5").src = '';
			}else if(file=='file6'){
				document.getElementById("img6").src = '';
			}
    };

    const onChangeValue = (e) => {
        if (e.target.type == "file") {
						var fileName1 = e.target.value;
			var idxDot = fileName1.lastIndexOf(".") + 1;
			var extFile = fileName1.substr(idxDot, fileName1.length).toLowerCase();
        if (extFile=="jpg" || extFile=="bmp" || extFile=="png"){
            setValues({
                ...values,
                [e.target.name]: e.target.files[0],
            });
			
			if(e.target.name=='file1'){
				document.getElementById("img1").src = URL.createObjectURL(e.target.files[0]);
			}else if(e.target.name=='file2'){
				document.getElementById("img2").src = URL.createObjectURL(e.target.files[0]);
				document.getElementById("hrf2").style.display = "none";
			}else if(e.target.name=='file3'){
				document.getElementById("img3").src = URL.createObjectURL(e.target.files[0]);
				document.getElementById("hrf3").style.display = "none";
			}else if(e.target.name=='file4'){
				document.getElementById("img4").src = URL.createObjectURL(e.target.files[0]);
				document.getElementById("hrf4").style.display = "none";
			}else if(e.target.name=='file5'){
				document.getElementById("img5").src = URL.createObjectURL(e.target.files[0]);
				document.getElementById("hrf5").style.display = "none";
			}else if(e.target.name=='file6'){
				document.getElementById("img6").src = URL.createObjectURL(e.target.files[0]);
				document.getElementById("hrf6").style.display = "none";
			}
			
			
			
        }else{
            swal({
                    title: "Image files can only be .jpg, .bmp or .png",
                    icon: "error",
                    button: " OK",
                })
			e.target.value='';
        } 
            if (e.target.name == "file1") {
                setValues({
                    ...values,
                    file1: e.target.files[0],
                });
            } else {
                console.log(e.target.type);
                console.log("filess", e.target.files[0]);
                setValues({
                    ...values,
                    [e.target.name]: e.target.files[0],
                });
                console.log(values);
            }
        } else if (e.target.name == "delivery") {
            console.log("Delivery : ", e.target.name, e.target.value);
            var previousDelivery = product.delivery;
            if (product.delivery.includes(+e.target.value)) {
                setProduct((prevState) => ({
                    ...prevState,
                    delivery: previousDelivery.filter((item) => item != e.target.value),
                }));
            } else {
                setProduct((prevState) => ({
                    ...prevState,
                    delivery: [...previousDelivery, +e.target.value],
                }));
            }
        } else {
            setProduct({
                ...product,
                [e.target.name]: e.target.value,
            });
            console.log("values", product[e.target.name]);
            console.log("name is: ", e.target.name);
            console.log("delivery", values.shipping_remember);
        }
    };

    const updateDescription = (description) => {
        setProduct((prevState) => ({
            ...prevState,
            description: description
        }));
    }

    const editProduct = async () => {
        console.log(
            "Products : ",
            product.description,
            product.title,
            product.subCategory,
            product.delivery
        );
        try {
            if (
                !product.description == "" &&
                !product.title == "" &&
                product.subCategory &&
                product.delivery.length != 0
            ) {
                const formData = new FormData();
                formData.append("id", product.id);
                formData.append("category_id", product.category);
                formData.append("subcategory_id", product.subCategory);
                formData.append("name", product.title);
                formData.append("description", product.description);
                formData.append(
                    "shipping_remember", values.shipping_remember || rating
                );
          

                if (values.file1) {
                    formData.append("primary_image", values.file1);
                }
				
				if (values.file2) {
                    formData.append("primary_image2", values.file2);
					formData.append("primary_image22", document.getElementById("hide2").value);
                }
				
				if (values.file3) {
                    formData.append("primary_image3", values.file3);
					formData.append("primary_image33", document.getElementById("hide3").value);
                }
				if (values.file4) {
                    formData.append("primary_image4", values.file4);
					formData.append("primary_image44", document.getElementById("hide4").value);
                }
				
				if (values.file5) {
                    formData.append("primary_image5", values.file5);
					formData.append("primary_image55", document.getElementById("hide5").value);
                }
				
				if (values.file6) {
                    formData.append("primary_image6", values.file6);
					formData.append("primary_image66", document.getElementById("hide6").value);
                }
				
				
				
		

                product.delivery.forEach((item, index) => {
                    formData.append(`shipping_id[${index}]`, item);
                });

            
                const config = {headers: {"Content-Type": "multipart/form-data"}};
                console.log("fordata before", formData);
                const res = await axios.post("items_update", formData, config);
                console.log("after formdata", formData);
                console.log(res);
                if (res.data.status) {
                    dispatch(fetchLocalUser());
                   
                    history.push("/myaccount/my-list");
                    emptyInputFiles();
                    await getProductDetails();
                    renewFiles();
                }
            } else {
				
				if(product.delivery.length == 0){
					 swal({
                    title: "Please select at least one shipping method",
                    icon: "error",
                    button: "OK",
                });
				}else{
					swal({
                    title: "Please input all fields",
                    icon: "error",
                    button: "OK",
                });
				}
                
            }
        } 
		
		
		catch (error) {
            console.log(error);
            swal({
                title: "Changes Saved Successfully",
                icon: "success",
                button: "OK",
            });
        }
    };

    const deleteImage = async (imageId,Id) => {
        try {
			document.getElementById("img"+Id).src = '';
			document.getElementById("hrf"+Id).style.display = "none";
            const response = await axios.get(`image_delete/${imageId}`);
            console.log("response from delete is:", response);
            if (response.data.status == true) {
                var newArray = oldImagesArray.filter((item) => item.id != imageId);
                setOldImagesArray(newArray);
				swal({
                title: "Deleted Successfully",
                icon: "success",
                button: "OK",
            });
            }
        } catch (error) {
            console.log("erro occured while deleting: ", error);
        }
    };

    return (
        <div className="account_content">
            <div className="acco_box">
                <div className="sub_title">
                    <h2>Save Changes</h2>
                </div>
                <div className="form_box">
                    <div className="row mlr-24">
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <select
                                    className="form-control form_input"
                                    name="category"
                                    onChange={(e) => {
                                        getSubCategory(e);
                                        onChangeValue(e);
                                    }}
                                    value={product.category}
                                >
                                    <option value="" disabled>
                                        Select categories
                                    </option>
                                    {categories.map((item, index) => {
                                        return <option value={item.id}>{item.name}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form_group">
                                <select
                                    className="form-control form_input"
                                    name="subCategory"
                                    onChange={(e) => {
                                        onChangeValue(e);
                                    }}
                                    value={product.subCategory}
                                >
                                    <option value="" disabled>
                                        Select subcategories
                                    </option>
                                    {subCategory.map((item, index) => {
                                        return (
                                            <option key={item.name} value={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12 plr-24">
                            <div className="form_group">
                                <input
                                    type="text"
                                    className="form-control form_input"
                                    placeholder="Enter Title"
                                    name="title"
                                    onChange={onChangeValue}
                                    value={product.title}
                                />
                                {/* <h5 style={{ color: "red" }}>{error.title}</h5> */}
                            </div>
                        </div>
                        <div className="col-md-12 plr-24">
                            <div className="form-group">

                                <CKEditor
                                    name="description"
                                    editor={ClassicEditor}
                                    data={product.description}
                                    // data={JSON.stringify(ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ))}
                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        console.log({event, editor, data});
                                        updateDescription(data)
                                    }}
                                    config={{
                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Indent", "BulletedList", "Table"]
                                    }}
                                />

                                {/* <textarea
                  className="form-control form_input"
                  placeholder="Enter Desciption"
                  name="description"
                  onChange={onChangeValue}
                  value={product.description}
                ></textarea> */}
                                {/* <h5 style={{ color: "red" }}>{error.description}</h5> */}
                            </div>
                        </div>
                       
                        <div  className="col-md-6 plr-24">
                            <div className="form-group">
                                1.
								
                                <input type='file' accept='image/jpg, image/bmp, image/png' onChange={(e) => onChangeValue(e)} name="file1"/>
                                <img   class="img-thumbnail1" id='img1'   />
								{
                                    values.file1 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file1')}></i>
                                }
                                <br/>
                                <small style={{color: "#856404"}} className="">* Primary Image</small>
                            </div>
                        </div>
                        <div  className="col-md-6 plr-24">
                            <div className="form-group">
                                4.
								<input type='hidden' id='hide4'/>
								<input type='hidden' id='id4'/>
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file4" onChange={(e) => onChangeValue(e)}
                                       name="file4"/>
									   <img class="img-thumbnail1" id='img4'   />
                                {
                                    values.file4 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file4')}></i>
                                }
								
								<a id="hrf4" style={{'margin-left':'10px','color':'black'}}
                                            className="deleteIcon removeFile"
                                            onClick={() => deleteImage((document.getElementById("id4").value),4)}
                                        >
                                            <i class="fa fa-times-circle"></i>
                                        </a>
                            </div>
                        </div>
						
						
						 <div className="col-md-6 plr-24">
                            <div className="form-group">
                                2.
								<input type='hidden' id='hide2'/>
								<input type='hidden' id='id2'/>
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file2" onChange={(e) => onChangeValue(e)}
                                       name="file2"/>
									   <img class="img-thumbnail1"  id='img2'   />
                                {
                                    values.file2 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file2')}></i>
                                }
									<a id="hrf2" style={{'margin-left':'10px','color':'black'}}
                                            className="deleteIcon removeFile"
                                            onClick={() => deleteImage((document.getElementById("id2").value),2)}
                                        >
                                            <i class="fa fa-times-circle"></i>
                                        </a>
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                5.
								<input type='hidden' id='hide5'/>
								<input type='hidden' id='id5'/>
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file5" onChange={(e) => onChangeValue(e)}
                                       name="file5"/>
									   <img class="img-thumbnail1" id='img5'   />
                                {
                                    values.file5 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file5')}></i>
                                }
								<a id="hrf5" style={{'margin-left':'10px','color':'black'}}
                                            className="deleteIcon removeFile"
                                            onClick={() => deleteImage((document.getElementById("id5").value),5)}
                                        >
                                            <i class="fa fa-times-circle"></i>
                                        </a>
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                3.
								<input type='hidden' id='hide3'/>
								<input type='hidden' id='id3'/>
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file3" onChange={(e) => onChangeValue(e)}
                                       name="file3"/>
									   <img class="img-thumbnail1" id='img3'   />
                                {
                                    values.file3 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file3')}></i>
                                }
								<a id="hrf3" style={{'margin-left':'10px','color':'black'}}
                                            className="deleteIcon removeFile"
                                            onClick={() => deleteImage((document.getElementById("id3").value),3)}
                                        >
                                            <i class="fa fa-times-circle"></i>
                                        </a>
                            </div>
                        </div>
                        <div className="col-md-6 plr-24">
                            <div className="form-group">
                                6.
									<input type='hidden' id='hide6'/>
								<input type='hidden' id='id6'/>
                                <input type='file' accept='image/jpg, image/bmp, image/png' className="mb-3" name="file6" onChange={(e) => onChangeValue(e)}
                                       name="file6"/>
									   <img class="img-thumbnail1" id='img6'   />
                                {
                                    values.file6 &&
                                    <i class="fa fa-times-circle removeFile"
                                       onClick={() => emptyInputFiles('file6')}></i>
                                }
								<a id="hrf6" style={{'margin-left':'10px','color':'black'}}
                                            className="deleteIcon removeFile"
                                            onClick={() => deleteImage((document.getElementById("id6").value),6)}
                                        >
                                            <i class="fa fa-times-circle"></i>
                                        </a>
                            </div>
                        </div>
                         <div className="col-md-12 plr-24">
                            <div className="form-group mt-5">
                                <label>
                                    <p className="lead font-weight-bold">Shipping Method</p>
                                </label>
                                <br/>

                                {deliveryserve.map((item, index) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <input
                                                type="checkbox"
                                                className="ml-5"
                                                id={item.name}
                                                value={item.id}
                                                onChange={(e) => onChangeValue(e)}
                                                name="delivery"
                                                checked={product.delivery.includes(item.id)}
                                            />
                                            <label for={item.name} value={item.id} className="ml-2">
                                                {item.name}
                                            </label>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>

                        {/* Primary Image Old */}
                       

                        <div className="col-md-6 plr-24 mt-4">
                            <button
                                type="submit"
                                className="btn btn-blue btn-blue-big"
                                onClick={editProduct}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddItem;
