import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import axios from 'axios';
import AdSense from "react-adsense";
function MostViewedTop() {
    const history = useHistory()
    const pushToDetail = (productId) => {
        history.push({ pathname: `/item-details/${productId}` });
    };
    const [products, setProducts] = useState([]);

    useEffect(() => {
        console.log("LATEST", products);
        axios
            // .get("get_product")
            .get("get_product_most_vie")
            .then((res) => {
                if (res.data.status == true && res.data.data.data?.length) {
                    console.log("Products are: ", res.data.data);
                    setProducts(res.data.data.data.slice(0, 5))
                    console.log("New Product Array: ", products);
                }
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <section className="latest_item_outer">
            <div className="container">
               
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>
                    {products.map((item) => {
                        return (
                            <div className="item" style={{ width: '25%', marginLeft: '10px' }} onClick={() => pushToDetail(item.id)}>
                                <div className="latest_item">
                                    <div className="latest_item_img">
                                        <img height="" src={item.primary_image} style={{ height: "180px" }} alt="" />
                                    </div>
                                   <h3 style={{ fontSize: "14px" }}>{item.name}</h3>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
           

        </section>

    )
}

export default MostViewedTop
