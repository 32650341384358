import React, {useState, useEffect} from "react";
import {useHistory} from "react-router";
import axios from "axios";

const PopularCategories = () => {
    const history = useHistory();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios
            .get("popular_category")
            .then((res) => {
                if (res.data.status == "true") {
                    console.log("Popular Categories are: ", res.data.data);
                    setCategories(res.data.data);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const goToCategoryProducts = (categoryId) => {
        history.push({
            pathname: `/current-items`,
            search: `?categoryId=${categoryId}`,
        });
    };

    return (
        <section className="popular_cat_outer">
            <div className="container">
                <div className="title text-center">
                    <h2>Popular Categories</h2>
                </div>
                <div className="popular_cat_list">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="popular_cat_box_outer">
                                {categories
                                    .filter((item, index) => index < 2)
                                    .map((category, index) => (
                                        <>
                                            <div
                                                className="popular_cat_box categoryItem"
                                                onClick={() => goToCategoryProducts(category.id)}
                                            >
                                                <a className="popular_cat_item d-align">
                                                    <div className="popular_cat_img">
                                                        <img src={category.image} alt=""/>
                                                    </div>
                                                    <div className="popular_cat_info">
                                                        <h3>{category.name}</h3>
                                                        <div className="link_icon">
                                                            <i className="fa fa-caret-right"></i>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            {index == 0 && <hr/>}
                                        </>
                                    ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                {categories
                                    .filter((item, index) => index >= 2 && index < 4)
                                    .map((category) => (
                                        <div
                                            className="col-md-6 categoryItem"
                                            onClick={() => goToCategoryProducts(category.id)}
                                        >
                                            <div className="popular_cat_part">
                                                <a className="popular_cat_item">
                                                    <div className="popular_cat_img">
                                                        <img src={category.image} alt=""/>
                                                    </div>
                                                    <div className="popular_cat_info">
                                                        <h3>{category.name}</h3>
                                                        <div className="link_icon">
                                                            <i className="fa fa-caret-right"></i>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                {categories
                                    .filter((item, index) => index == 4)
                                    .map((category) => (
                                        <div
                                            className="col-lg-12 categoryItem"
                                            onClick={() => goToCategoryProducts(category.id)}
                                        >
                                            <div className="popular_cat_box_outer mt-30">
                                                <div className="popular_cat_box">
                                                    <a className="popular_cat_item d-align">
                                                        <div className="popular_cat_img">
                                                            <img src={category.image} alt=""/>
                                                        </div>
                                                        <div className="popular_cat_info">
                                                            <h3>{category.name}</h3>
                                                            <div className="link_icon">
                                                                <i className="fa fa-caret-right"></i>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PopularCategories;
