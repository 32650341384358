import React, {useEffect, useState} from "react";
import AccountSidebar from "../Components/AccountComponents/AccountSidebar";
import AccountDetails from "../Components/AccountComponents/AccountDetails";
import AddItem from "../Components/AccountComponents/AddItem";
import EditItem from "../Components/AccountComponents/EditItem";
import MyItems from "../Components/AccountComponents/MyItems";

import MyListedItemsClaimed from "../Components/AccountComponents/MyListedItemsClaimed";
import MyChats from "../Components/AccountComponents/MyChats";
import ClaimedItems from "../Components/AccountComponents/ClaimedItems";
import SellCredit from "../Components/AccountComponents/SellCredit";
import Transactions from "../Components/AccountComponents/Transactions";
import Dashboard from "../Components/AccountComponents/Dashboard";
import Messages from "../Components/AccountComponents/Messages";
import SendMessage from "../Components/AccountComponents/SendMessage";
import Message from "../Components/AccountComponents/Message";

import {Route, Switch, useRouteMatch,} from "react-router-dom";
import ShippingDetails from "./ShippingDetails";

function MyAccount() {

    let {path, url} = useRouteMatch();

    return (
        <div className="account_box_outer mt-120">
            <div className="container">
                <div className="account_box">
                    <AccountSidebar curUrl={path}/>
                    <Switch>
                        <Route exact path={`${path}/addItem`} component={AddItem}></Route>
                        <Route
                            exact
                            path={`${path}/edit-item/:id`}
                            component={EditItem}
                        ></Route>
                        <Route
                            exact
                            path={`${path}/accountdetails`}
                            component={AccountDetails}
                        ></Route>
                        <Route exact path={`${path}/my-list`} component={MyItems}></Route>
                        <Route exact path={`${path}/my-chats`}>
                            <MyChats/>
                        </Route>
                        <Route exact path={`${path}/myclaimeditems`}>
                            <MyListedItemsClaimed/>
                        </Route>
                        <Route
                            exact
                            path={`${path}/my-messages`}
                            component={Messages}
                        ></Route>
                        <Route
                            exact
                            path={`${path}/shipping/:itemId`}
                            component={ShippingDetails}
                        ></Route>
                        <Route
                            exact
                            path={`${path}/message/:mid`}
                            component={Message}
                        ></Route>
                        <Route
                            exact
                            path={`${path}/my-messages/:uid/:pid`}
                            component={SendMessage}
                        ></Route>
                        <Route path={`${path}/claimeditems`}>
                            <ClaimedItems/>
                        </Route>
                        <Route path={`${path}/sellcredit`}>
                            <SellCredit/>
                        </Route>
                        <Route path={`${path}/transactions`}>
                            <Transactions/>
                        </Route>
                        <Route path={`${path}/dashboard`}>
                            <Dashboard/>
                        </Route>
                    </Switch>

                    {/* <AddItem /> */}
                </div>
            </div>
        </div>
    );
}

export default MyAccount;
