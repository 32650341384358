import {
    FETCH_MESSAGES_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    FETCH_USER_REMOVE,
    FETCH_TOTAL_PRODUCT_SUCCESS
} from './userTypes';

import axios from 'axios';

export const fetchUserSuccess = (user) => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: user
    }
}

export const fetchUserRemove = () => {
    return {
        type: FETCH_USER_REMOVE,
    }
}

export const fetchUserError = (error) => {
    return {
        type: FETCH_USERS_FAILURE,
        payload: error
    }
}

export const fetchTotalProductSuccess = (payload) => {
    return {
        type: FETCH_TOTAL_PRODUCT_SUCCESS,
        payload
    }

}

export const fetchMessagesSuccess = (payload) => {
    return {
        type: FETCH_MESSAGES_SUCCESS,
        payload
    }

}

export const fetchLocalUser = () => {
    return async (dispatch) => {
        try {
            const res = await axios.get('token')
            if (res.data.status = "true") {
                dispatch(fetchUserSuccess(res.data.response))
            }
        } catch (error) {
            dispatch(fetchUserError(error))
        }
    }
}

export const fetchTotalProducts = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('total_products')
            if (response.data.data.products) {
                dispatch(fetchTotalProductSuccess({
                    totalProducts: response.data.data.products
                }))
            }
        } catch (error) {
            dispatch(fetchTotalProductSuccess({
                totalProducts: 0
            }))
        }
    }
}

export const fetchMessages = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('messages')
            if (response.data.data.products) {
                dispatch(fetchMessagesSuccess({
                    messages: response.data.data.messages
                }))
            }
        } catch (error) {

        }
    }
}