import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Link, Switch, useRouteMatch, NavLink, useHistory,} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {fetchUserRemove} from "../../Redux";
import axios from "axios";
import swal from "sweetalert";

function AccountSidebar(props) {

    let {path, url} = useRouteMatch();

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("urlEFECT", {
            path,
            url,
            props,
        });
    });

    useEffect(async () => {
        await mounted();
    }, []);

    const mounted = async () => {
        await getNewMessagesCount()
    };

    const [message, setMessageCount] = useState({
        count: 0
    });

    const getNewMessagesCount = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`messages/new/count`);
                setMessageCount({
                    count: response.data.new_messages
                })
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    };


    const logout = async () => {
        dispatch(fetchUserRemove());
        try {
            const logOut = await axios.get("logout");
            console.log({logOut});
        } catch (error) {
            console.log(error);
        }
        history.replace({pathname: "/"});
        swal({
            title: "You Successfully Logged Out",
            icon: "success",
            button: " OK",
        });
    };

    return (
        <div class="account_box">
            <div class="sidebar">
                <div class="sidebar_links sidebar_links_first">
                    <ul>
                        <li>
                            <NavLink
                                to={`${props.curUrl}/dashboard`}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/icon-dashboard.png" alt=""/>
                                </div>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`${props.curUrl}/my-list`}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/icon-my-listed-items.png" alt=""/>
                                </div>
                                <span>My Listed Items</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`${props.curUrl}/myclaimeditems`}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/icon-my-listed-items-claimed.png" alt=""/>
                                </div>
                                <span>My Listed Items Claimed</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to={`${props.curUrl}/claimeditems`}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/icon-items-that-i-claimed.png" alt=""/>
                                </div>
                                <span>Items That I Claimed</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`${props.curUrl}/my-messages`}
                                onClick={getNewMessagesCount}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/my-messages.png" alt=""/>
                                </div>
                                <span>
                                    My Messages
                                    {message.count > 0 ? (
                                        <div class="text_red text_small"><b>New Message!</b></div>
                                    ) : null}
                                </span>
                            </NavLink>
                        </li>
                        {/* <li class="active" > */}
                        <li>
                            <NavLink
                                to={`${props.curUrl}/accountdetails`}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/icon-account-details.png" alt=""/>
                                </div>
                                <span>Account Details</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`${props.curUrl}/additem`}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/icon-add-item.png" alt=""/>
                                </div>
                                <span>Add Item</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`${props.curUrl}/sellcredit`}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/icon-sell-credits.png" alt=""/>
                                </div>
                                <span>Sell Credit(s)</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`${props.curUrl}/transactions`}
                                activeClassName="active_side_bar"
                            >
                                <div class="acc_icon">
                                    <img src="/images/icon-transactions.png" alt=""/>
                                </div>
                                <span>Transactions</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div class="sidebar_links mt-140">
                    <ul>
                        <li>
                            <a href="#" onClick={logout}>
                                <div class="acc_icon">
                                    <img src="/images/icon-logout.png" alt=""/>
                                </div>
                                <span>Log Out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AccountSidebar;
