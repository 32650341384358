import React, { useEffect } from 'react'

import Header from "../Layout/Header/Index";
import Footer from "../Layout/Footer";
import Login from '../Screens/Login';
import SignUp from '../Screens/SignUp';
import Home from '../Screens/Home'
import AboutUs from '../Screens/AboutUs';
import Protected from '../Helpers/Protected';
import MyAccount from '../Screens/MyAccount';
import HowItWorks from '../Screens/HowItWorks';
import BuyCredits from '../Screens/BuyCredits';
import ItemDetails from '../Screens/ItemDetails';
import CurrentItems from '../Screens/CurrentItems';
import ContactUs from '../Screens/ContactUs';
import Forgotpassword from '../Components/Login/Forgotpassword'
import BuyStep1 from '../Components/BuyCredits/BuyStep1'
import BuyStep2 from '../Components/BuyCredits/BuyStep2'

import TnC from '../Screens/TnC';
import { BrowserRouter as Router, Route, Link, useRouteMatch } from "react-router-dom"
import PrivacyPolicy from '../Screens/PrivacyPolicy';

function Routes(props) {
    return (
        //<Router forceRefresh >
        <Router >
            <Header />
            <Route component={Home} exact path='/' />
            <Route component={Login} path='/Login' />
            <Route component={SignUp} path='/SignUp' />
            <Route component={HowItWorks} path='/HowItWorks' />
            <Route component={ItemDetails} path='/item-details/:itemId' />
            <Route component={CurrentItems} path='/current-items' />
            <Route component={ContactUs} path='/contactUs' />
            <Route component={Forgotpassword} path='/forgotpassword' />
            <Route component={BuyStep1} path='/BuyStep1' />
            <Route component={BuyStep2} path='/BuyStep2' />
            <Route component={PrivacyPolicy} path='/PrivacyPolicy' />
            <Route path='/myaccount'><Protected Component={MyAccount} /></Route>
            <Route path='/about'>
                <AboutUs />
            </Route>
            <Route path='/BuyCredits'>
                <Protected Component={BuyCredits} />
            </Route>
            <Route path='/terms'>
                <TnC />
            </Route>
            <Footer />
        </Router>
    )
}

export default Routes
