import React, { useState, useEffect } from 'react'
import axios from 'axios'


export default function HowItWorks() {

    const [hiwdata, setHwiData] = useState([])

    const fetchHwi = async () => {
        var res = await axios.get('pages')
        setHwiData(res.data.data)
    }
    useEffect(() => {
        fetchHwi()
    }, [])


    return (
        <>
            <section>
                <div className="container">
                    <div className="login_sub_title mb-5">
                        <h2 className="text-center mt-5">{hiwdata.length !== 0 ? <div> {hiwdata[1].title}</div> : ""}</h2>
                        <p>{hiwdata.length !== 0 ? <p dangerouslySetInnerHTML={{ __html: hiwdata[1].description }} /> : ""}</p>
                    </div>
                </div>
            </section>
            <section className="work_outer">
                <div className="container">
                    <div className="work_list">
                        <div className="row">
                            <div className="col-md-3 col-lg-3">
                                <div className="work_item">
                                    <div className="work_img">
                                        <img src="images/icon-work_01.png" alt="" />
                                        <div className="work_number">1</div>
                                    </div>
                                    <p>Add Your Item</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="work_item">
                                    <div className="work_img">
                                        <img src="images/icon-work_02.png" alt="" />
                                        <div className="work_number">2</div>
                                    </div>
                                    <p>Members Can Claim Your Item For Free</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="work_item">
                                    <div className="work_img">
                                        <img src="images/icon-work_03.png" alt="" />
                                        <div className="work_number">3</div>
                                    </div>
                                    <p>Ship Item To Member

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="work_item">
                                    <div className="work_img">
                                        <img src="images/icon-work_04.png" alt="" />
                                        <div className="work_number">4</div>
                                    </div>
                                    <p>You Earn One Credit To Claim Something You Want</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
}