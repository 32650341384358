
import React, { useState } from 'react'
import axios from 'axios'
import swal from "sweetalert";



const Forgotpassword = (props) => {

    const [value, setValue] = useState({
        email: "",
    })
    const [emailerror, setEmailError] = useState('')

    const handleSubmit = async (e) => {
        setEmailError("")
        if (!value.email) {
            setEmailError("Please enter your email address")
        }
        if (value.email) {

            const data = { email: value.email }
            var res = await axios.post('forgot-password', data)
            console.log('email responcse', res)
            if (res.data.status == true) {
                swal({
                    title: "Password Reset Link has been sent to your email",
                    icon: "success",
                    button: "OK",
                })
            }
            else if (res.data.message.email == "The email must be a valid email address.") {
                setEmailError("Please type valid email address")
            }
            else {
                swal({
                    title: "Email not found",
                    icon: "error",
                    button: "OK",
                })
            }


        }

    }

    const handleChange = (e) => {

        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
        console.log({ value });
    }


    return (
        <div>
            <section className="account_box_outer mt-120 " >
                <div className="container offset-xl-3 ">
                    <div className="account_box">
                        <div className="account_content">
                            <div >
                                <div className="row">
                                    <div className="login_sub_title col-md-9">
                                        <h2>Forgot password</h2>

                                    </div>
                                </div>
                                <div className="form_box">
                                    <div className="row mlr-24">
                                        <div className="col-md-8 plr-24 mb-4">
                                            <div className="form_group d-flex">
                                                <i className="fa fa-envelope form_input pt-3" aria-hidden="true"></i>
                                                <input type="email" className="form-control form_input" placeholder="Enter Email" type="email" name="email" value={value.email} onChange={handleChange} />
                                            </div>
                                            {emailerror ? (
                                                <p style={{
                                                    margin: '20px',
                                                    color: "red",
                                                    fontSize: '14px',
                                                    lineHeight: '0px',
                                                }}>
                                                    {emailerror}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6 plr-24 mb-4">
                                            <button type="submit" className="btn btn-blue w-50"
                                                onClick={handleSubmit}
                                            >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Forgotpassword
